import { AxiosInstance } from "axios";
import AssetCustomField from "../models/AssetCustomField";

export default class AssetCustomFieldsApi {
    constructor(public readonly axiosInstance: AxiosInstance) {}

    fetchAssetCustomFields(assetTypeId: number): Promise<AssetCustomField[]> {
        const url = `/api/asset_custom_fields?assetTypeId=${assetTypeId}`;
        return this.axiosInstance.get(url).then(resp => resp.data.map(AssetCustomField.fromJson));
    }
}