import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DrawingType, Status} from "../../../../../models/enums";
import "./modalContent.scss";
import TextInput from "../../../../project-drawings/features/editor/components/TextInput";
import DatePresenter from "../../../../../components/ui/DatePresenter";
import useDrawingDetails from "../../../../../hooks/useDrawingDetails";
import UserSummary from "../../../../../models/UserSummary";
import Button from "../../../../../components/ui/Button";
import useDrawingLockUnlock from "../../../hooks/useDrawingLockUnlock";
import ConfirmationModal from "../../../../../components/ui/ConfirmationModal";
import NotificationService from "../../../../../services/NotificationService";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {ModalWithTitleAndButtons} from "../../../../../components/modal";
import InlineSpinner from "../../../../../components/InlineSpinner";

interface DrawingInfoModalProps {
	isOpen: boolean,
	onClose: () => void,
	drawingId: string,
}

const DrawingInfoModal: React.FC<DrawingInfoModalProps> = ({isOpen, onClose, drawingId}) => {

	const {t} = useTranslation()
	const {loadStatus, drawingDetails, loadData} = useDrawingDetails();
	const {forceTreeUpdate, userData: {users, usersLoadStatus}} = useProjectDetailContext()
	const loadDataRef = useUpdatedRef(loadData);
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	const {unlockDrawing} = useDrawingLockUnlock(drawingId, drawingType);
	const [modUser, setModUser] = useState<UserSummary | undefined>()
	const [lockUser, setLockUser] = useState<UserSummary | undefined>()
	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

	useEffect(() => {
		if (isOpen) {
			const {current: loadData} = loadDataRef;
			loadData(drawingId);
		}
	}, [isOpen, drawingId, loadDataRef]);

	useEffect(() => {
		if (loadStatus === Status.SUCCESS && usersLoadStatus === Status.SUCCESS) {
			const modUser = users.find(u => u.id === drawingDetails?.modifiedBy);
			const lockUser = users.find(u => u.id === drawingDetails?.lockedBy);
			setModUser(modUser);
			setLockUser(lockUser);
		}
	}, [loadStatus, usersLoadStatus, drawingDetails, users])

	const translations = {
		"unlockSuccess": {
			[DrawingType.TAKE_OFF]: t(`projects.details.drawingInfoModal.unlockSuccessDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.drawingInfoModal.unlockSuccessDesc.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.drawingInfoModal.unlockSuccessDesc.asset_plan`),
		},
		"modalTitle": {
			[DrawingType.TAKE_OFF]: t(`projects.details.drawingInfoModal.title.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.drawingInfoModal.title.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.drawingInfoModal.title.asset_plan`)
		},
		"modalText": {
			[DrawingType.TAKE_OFF]: t(`projects.details.drawingInfoModal.unlockConfirmationText.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.drawingInfoModal.unlockConfirmationText.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.drawingInfoModal.unlockConfirmationText.asset_plan`)
		}
	}

	const onUnlockClick = () => {
		setConfirmationModalVisible(false);
		unlockDrawing(() => {
			NotificationService.successNotification(t("common.success"),
				translations["unlockSuccess"][drawingType]);
			loadData(drawingId);
			forceTreeUpdate();
		});
	};

	return (
		<>
			<ModalWithTitleAndButtons
				isOpen={isOpen}
				onClose={onClose}
				title={translations["modalTitle"][drawingType]}
				modalButtons={(<>
					{loadStatus === Status.LOADING && <InlineSpinner/>}
					<Button
						label={t(("common.buttons.close"))}
						variant={"primary"}
						onClick={onClose}
						disabled={loadStatus === Status.LOADING}
					/>
				</>)}
			>
				<div className={"modal-content_message"}>
					<div className="modal-content_field">
						<div className="modal-content_field_label">
							{t("projects.details.drawingInfoModal.originalDrawingLabel")}
						</div>
						<TextInput value={drawingDetails?.planFilename || ""}
								   disabled={true}/>
					</div>
					<div className="modal-content_field">
						<div className="modal-content_field_label">
							{t("projects.details.drawingInfoModal.sizeLabel")}
						</div>
						<TextInput value={drawingDetails?.getContentInMegabytes() || ""}
								   disabled={true}/>
					</div>
					<div className="modal-content_field">
						<div className="modal-content_field_label">
							{t("projects.details.drawingInfoModal.dateUpdatedLabel")}
						</div>
						<div className={"modal-content_field_container"}>
							{drawingDetails ? <DatePresenter date={drawingDetails?.modifiedAt}/> : null}
						</div>
					</div>
					<div className="modal-content_field">
						<div className="modal-content_field_label">
							{t("projects.details.drawingInfoModal.modifiedByLabel")}
						</div>
						<TextInput value={modUser?.name || ""}
								   disabled={true}/>
					</div>
					{lockUser &&
						<div className="modal-content_field_with-button-container">
							<div className="modal-content_field">
								<div className="modal-content_field_label">
									{t("projects.details.drawingInfoModal.lockedByLabel")}
								</div>
								<TextInput value={lockUser.name}
										   disabled={true}/>
							</div>
							<Button variant={"secondary"}
									label={t("projects.details.drawingInfoModal.unlockButtonLabel")}
									onClick={() => setConfirmationModalVisible(true)}/>
						</div>}
				</div>
			</ModalWithTitleAndButtons>
			<ConfirmationModal isOpen={confirmationModalVisible}
							   text={translations["modalText"][drawingType]}
							   title={t("projects.details.drawingInfoModal.unlockConfirmationTitle")}
							   confirmationButtonLabel={t("projects.details.drawingInfoModal.unlockButtonLabel")}
							   onConfirm={onUnlockClick}
							   onClose={() => setConfirmationModalVisible(false)}/>
		</>
	);
};

export default DrawingInfoModal;
