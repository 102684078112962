import {useTranslation} from "react-i18next";
import {IconPin} from "../../icons/side_panel/IconPin";
import Button from "../../../../../../components/ui/Button";
import React, {useState} from "react";
import {Status} from "../../../../../../models/enums";
import Spinner from "../../../../../../components/Spinner";
import DataFetchError from "../../../../../../components/ui/DataFetchError";
import {useEditorPanelDataContext} from "./EditorPanelProvider";
import {EditorPanelMode, MeasurementType} from "../../models/enums";
import {CheckListItem, DeleteActionListItem, TableColumnConfig} from "../../../../../../models/interfaces";
import {PinnedMeasurement} from "../../../../../../models/PinnedMeasurement";
import TableHeaderCell from "../../../../../../components/layout/table/TableHeaderCell";
import TableNoRecords from "../../../../../../components/layout/table/TableNoRecords";
import {useTableSelectionState} from "../../../../../../hooks/useTableSelectionState";
import ConfigHelper from "../config/ConfigHelper";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {CountMeasurementStyle, MaterialMeasurementCreateActionPayload} from "../../models/editor";
import {countActions} from "../count/countSlice";
import {lengthActions} from "../length/lengthSlice";
import {areaActions} from "../area/areaSlice";
import {configActions} from "../config/configSlice";
import {toMaterialAddableTool} from "../../../../../../utils/PinnedUtils";
import {useDispatch, useSelector} from "react-redux";
import ScaleModal from "../scale/ScaleModal";
import {useScaleSetDataContext} from "../scale/ScaleSetProvider";
import IconDeletionMinus from "../../icons/IconDeletionMinus";
import SymbolPreviewElement from "../../../../../symbols/components/SymbolPreviewElement";
import {selectScaleConfig} from "../scale/scaleSlice";

export function PinnedItemsEditorPanel() {

	const {t} = useTranslation();
	const dispatch = useDispatch();
	const [isScaleOpen, setIsScaleOpen] = useState(false);
	const {openScaleSetModal} = useScaleSetDataContext()
	const scaleConfig = useSelector(selectScaleConfig);
	const {
		setEditorPanelMode,
		pinnedItems: {
			loadStatus,
			pinnedMeasurements,
			deletePinnedMeasurement
		}
	} = useEditorPanelDataContext()

	const {header, row} = useTableSelectionState<PinnedMeasurement, string>(pinnedMeasurements)
	const canAddItems = pinnedMeasurements.some(item => row.isSelected(item.id))

	const columns: TableColumnConfig<DeleteActionListItem<CheckListItem<PinnedMeasurement>>>[] = [
		{
			id: "checked",
			label: "",
			sortable: false,
			width: 40
		}, {
			id: "materialName",
			label: t("editor.panel.pinnedItems.table.columns.symbolAndName"),
			sortable: false,
			width: "80%"
		}, {
			id: "measurementType",
			label: t("editor.panel.pinnedItems.table.columns.type"),
			sortable: false,
			width: "20%"
		}, {
			id: "delete",
			label: "",
			sortable: false,
			width: 40
		}
	]

	function onScaleModalConfirm() {
		setIsScaleOpen(false);
		openScaleSetModal()
	}

	function handleAddItems() {
		if (!canAddItems) return;

		for (let pinnedMeasurement of pinnedMeasurements) {
			if (row.isSelected(pinnedMeasurement.id)) {
				if (!ConfigHelper.isScaleSet(scaleConfig)) {
					setIsScaleOpen(true);
				}
				else {
					let measurementMaterialAddAction: ActionCreatorWithPayload<MaterialMeasurementCreateActionPayload>;

					switch (pinnedMeasurement.measurementType) {
						case MeasurementType.COUNT:
							measurementMaterialAddAction = countActions.addCount;
							break;
						case MeasurementType.LENGTH:
							measurementMaterialAddAction = lengthActions.addLength;
							break;
						default:
							measurementMaterialAddAction = areaActions.addArea;
							break;
					}
					dispatch(measurementMaterialAddAction({
						material: {
							id: pinnedMeasurement.materialId,
							name: pinnedMeasurement.materialName,
							partNumber: pinnedMeasurement.materialPartNumber,
							laborTime: pinnedMeasurement.labourTime,
							type: pinnedMeasurement.materialType,
							isAsset: pinnedMeasurement.isAsset,
							assetTypeId: pinnedMeasurement.assetTypeId
						},
						style: pinnedMeasurement.style,
						defaultDrop: pinnedMeasurement.defaultDrop,
						height: pinnedMeasurement.height
					}));
					dispatch(
						configActions.switchEditorTool({editorTool: toMaterialAddableTool(pinnedMeasurement.measurementType)}));
				}
			}
		}
		setEditorPanelMode(EditorPanelMode.TAKEOFF_ITEMS)
	}

	function symbolPreview(item: PinnedMeasurement) {
		if (item.measurementType === MeasurementType.COUNT) {
			const style = item.style as CountMeasurementStyle
			return (
				<div className={"pinned-symbol-name_icon"}>
					<SymbolPreviewElement
						symbol={style.symbol}
						remoteSymbol={style.remoteSymbol}
						color={style.color}
					/>
				</div>
			)
		}
		return null
	}

	return (
		<>
			{isScaleOpen &&
				<ScaleModal isOpen={true}
							onClose={() => setIsScaleOpen(false)}
							onSave={onScaleModalConfirm}
							disableSave={false}/>}
			<div className="editor-panel_container_heading">
				<div className="editor-panel_container_heading_label">
					<IconPin/>{t("editor.panel.title.pinnedItems")}
				</div>
				<div className="editor-panel_container_heading_spacer"/>
				<Button label={t("common.buttons.cancel")} variant={"secondary"}
						onClick={() => setEditorPanelMode(EditorPanelMode.TAKEOFF_ITEMS)}/>
				<Button label={t("editor.panel.pinnedItems.pinnedItemsListAddItemsLabel")} variant={"primary"}
						onClick={handleAddItems} disabled={!canAddItems}/>
			</div>
			<div className="editor-panel_container_content">
				{loadStatus === Status.LOADING ? <Spinner/> : null}
				{loadStatus === Status.SUCCESS ? (<>
					<table className="table">
						<thead>
						<tr>
							{columns.map(col => {
								if (col.id === "checked") {
									return (
										<th style={{width: col.width}} key={col.id}>
											<input type={"checkbox"} checked={header.isSelected} onChange={header.toggle}/>
										</th>
									)
								}
								return (
									<TableHeaderCell key={col.id} id={col.id} label={col.label} width={col.width}/>
								);
							})}
						</tr>
						</thead>
						<tbody>
						{pinnedMeasurements.length ? (
							pinnedMeasurements.map(item => (
								<tr key={item.id}>
									<td><input type={"checkbox"} checked={row.isSelected(item.id)}
											   onChange={() => {row.toggle(item.id)}}/></td>
									<td>
										<div className="pinned-symbol-name">
											{symbolPreview(item)}
											<div className="pinned-symbol-name_label">{item.materialName}</div>
											<div className="pinned-symbol-name_pinned-icon"><IconPin/></div>
											<div className="pinned-symbol-name_spacer"/>
										</div>
									</td>
									<td>{item.measurementType}</td>
									<td>
										<div className="pinned-delete-icon" onClick={() => deletePinnedMeasurement(item.id)}>
											<IconDeletionMinus/>
										</div>
									</td>
								</tr>
							))
						) : (
							<TableNoRecords cols={columns.length}/>
						)}
						</tbody>
					</table>
				</>) : null}
				{loadStatus === Status.ERROR ? <DataFetchError/> : null}
			</div>
		</>
	)
}
