import React from "react";
import {useDispatch} from "react-redux";
import "./groupItemPresenter.scss";
import {useHighlightItem, useHighlightSetter} from "../hooks/highlight";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import IconVisibleButton from "./IconVisibleButton";
import {
	DeleteActionPayload,
	DeleteChildItemActionPayload,
	SetItemVisibilityActionPayload,
	TextItem,
} from "../../../../base-konva/types";

interface CountGroupItemProps {
	parentId: string,
	item: TextItem,
	onDelete: ActionCreatorWithPayload<DeleteActionPayload | DeleteChildItemActionPayload, string>,
	setItemVisibility: ActionCreatorWithPayload<SetItemVisibilityActionPayload, string>,
	disabled?: boolean,
	isAssetPlan: boolean
}

const TextItemPresenter: React.FC<CountGroupItemProps> = ({parentId, item, onDelete, setItemVisibility, disabled, isAssetPlan}) => {
	const dispatch = useDispatch();
	const highlightActions = useHighlightSetter(item.id);
	const {isItemHover} = useHighlightItem(item.id);

	const toggleVisibility = () => {
		dispatch(setItemVisibility({id: item.id, parentId, visible: !item.visible}))
	}

	const handleDelete = () => {
		if (!isAssetPlan) {
			!disabled && dispatch(onDelete({parentId, id: item.id}));
		}
	}

	const cls = [
		"group-item-presenter",
		isItemHover && "is-hover",
	].filter(Boolean).join(" ");

	const actionsButtonCls = [
		"group-item-presenter_actions_button",
		disabled && "disabled"
	].filter(Boolean).join(" ");

	return (
		<div className={cls}
			 onMouseEnter={highlightActions.setHighlight}
			 onMouseLeave={highlightActions.clearHighlight}>
			<div className={"group-item-presenter_label"}>
				{item.text}
			</div>
			<div className={"group-item-presenter_actions"}>
				<IconVisibleButton onClick={toggleVisibility} visible={item.visible}/>
				<div className={actionsButtonCls} onClick={handleDelete}>
					&times;
				</div>
			</div>
		</div>
	);
};

export default TextItemPresenter
