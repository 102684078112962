import {DrawingVersionApiDto} from "./restModels";
import Version from "./Version";
import {VersionLabel} from "./enums";
import i18n from "i18next";
import moment from "moment";

export class DrawingVersion {
	constructor(public readonly id: string,
		public readonly locked: boolean,
		public readonly modifiedAt: moment.Moment,
		public readonly modifiedBy: string | undefined,
		public readonly version: Version,
		public labels: VersionLabel[] = [],
	) {}

	public static fromJson(json: DrawingVersionApiDto) {
		const version = Version.fromJson(json.version)
		return new DrawingVersion(
			json.id,
			json.locked,
			moment(json.modifiedAt),
			json.modifiedBy ?? undefined,
			version,
		)
	}

	isCompleted(): boolean {
		return this.version.major > 0 && this.version.minor === 0;
	}

	set versionLabels(labels: VersionLabel[]) {
		this.labels = labels
	}

	get formattedLabels(): string {
		return this.labels.filter(v => v !== VersionLabel.REVERTABLE).map(
			l => i18n.t(`projects.details.tree.versionLabels.${l.toLowerCase()}`)).join(", ")
	}
}
