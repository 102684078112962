import {useTranslation} from "react-i18next";
import MeasurementMaterialAddButton from "../../components/MeasurementMaterialAddButton";
import {Accordion} from "./accordion/Accordion";
import {IconCount} from "../../icons/side_panel/IconCount";
import CountPanelTab from "./CountPanelTab";
import {IconLength} from "../../icons/side_panel/IconLength";
import LengthPanelTab from "./LengthPanelTab";
import {IconArea} from "../../icons/side_panel/IconArea";
import AreaBasedPanelTab from "./AreaBasedPanelTab";
import {EditorPanelTabKeys, EditorTool} from "../../models/enums";
import {IconVolume} from "../../icons/side_panel/IconVolume";
import LayersPanelTab from "./LayersPanelTab";
import React, {useState} from "react";
import {useEditorPanelDataContext} from "./EditorPanelProvider";
import Button from "../../../../../../components/ui/Button";
import {EditorPanelModal} from "../../../../../../models/enums";
import ProjectPlanSummaryModal from "../../../../components/summary/ProjectPlanSummaryModal";
import {IconDrawn} from "../../icons/side_panel/IconDrawn";

export function TakeoffsEditorPanel({isAssetPlan}: {isAssetPlan: boolean}) {
	const [modal, setModal] = useState<EditorPanelModal>(EditorPanelModal.NONE);
	const {t} = useTranslation();
	const {takeoffs: {getExpandState}} = useEditorPanelDataContext()

	return (
		<>
			<div className="editor-panel_container_heading">
				{isAssetPlan ? t("editor.panel.title.assetCreation") : t("editor.panel.title.takeoff")}
				<div className="editor-panel_container_heading_spacer"/>
				<Button label={t("projects.summary.buttonLabel")} variant={"tertiary"}
						onClick={() => setModal(EditorPanelModal.SUMMARY)}/>
				{!isAssetPlan && <MeasurementMaterialAddButton/>}
			</div>
			<div className="editor-panel_container_content">
				<Accordion expand={getExpandState(EditorPanelTabKeys.COUNT)} icon={<IconCount/>}
						   label={t("editor.editorPanelTabs.count")}>
					<CountPanelTab isAssetPlan={isAssetPlan}/>
				</Accordion>
				<Accordion expand={getExpandState(EditorPanelTabKeys.LENGTH)} icon={<IconLength/>}
						   label={t("editor.editorPanelTabs.length")}>
					<LengthPanelTab isAssetPlan={isAssetPlan}/>
				</Accordion>
				<Accordion expand={getExpandState(EditorPanelTabKeys.AREA)} icon={<IconArea/>}
						   label={t("editor.editorPanelTabs.area")}>
					<AreaBasedPanelTab type={EditorTool.AREA} isAssetPlan={isAssetPlan}/>
				</Accordion>
				<Accordion expand={getExpandState(EditorPanelTabKeys.VOLUME)} icon={<IconVolume/>}
						   label={t("editor.editorPanelTabs.volume")}>
					<AreaBasedPanelTab type={EditorTool.VOLUME} isAssetPlan={isAssetPlan}/>
				</Accordion>
				<Accordion expand={getExpandState(EditorPanelTabKeys.LAYERS)} icon={<IconDrawn/>}
						   label={t("editor.editorPanelTabs.layers")}>
					<LayersPanelTab isAssetPlan={isAssetPlan}/>
				</Accordion>
			</div>
			{modal === EditorPanelModal.SUMMARY &&
				<ProjectPlanSummaryModal onClose={() => setModal(EditorPanelModal.NONE)}/>}
		</>
	)
}
