import {AssetCustomFieldApiDto} from "./restModels";

export default class AssetCustomField {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly type: string) {}

    public static fromJson(json: AssetCustomFieldApiDto): AssetCustomField {
        return new AssetCustomField(json.id, json.name, json.type);
    }
}