import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import useAssetCustomFields from "../../../../../hooks/useAssetCustomFields";
import { Status } from "../../../../../models/enums";
import "./BulkAssetAssignment.scss"

interface AssetCustomFieldsComponentProps {
    assetTypeId: number | undefined;
}

const AssetCustomFieldsComponent: React.FC<AssetCustomFieldsComponentProps> = ({ assetTypeId }) => {
    const {t} = useTranslation();
    const { assetCustomFields, loadStatus } = useAssetCustomFields(assetTypeId);
    const [inputValues, setInputValues] = useState<string[]>(Array(assetCustomFields.length).fill(''));
    const customFieldsLimit = 4;

    const handleInputChange = (index: number, value: string) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };

    if (loadStatus === Status.LOADING) return <div>{t("projects.summary.itemsTable.updateAssetPlan.loading")}</div>;
    if (loadStatus === Status.ERROR) return <div>{t("projects.summary.itemsTable.updateAssetPlan.loadErrorDesc")}</div>;
    if (loadStatus === Status.NO_DATA) return <div>{t("projects.summary.itemsTable.updateAssetPlan.noDataDesc")}</div>;

    return (
        <div className='asset-custom-fields_box'>
            <h3 className='asset-custom-fields_title'>DEFINE DEFAULTS</h3>
            <ul className='asset-custom-fields_list'
                style={{height: assetCustomFields.length >= customFieldsLimit ? 300 : 200}}>
                {assetCustomFields.map((assetCustomField, index) => (
                    <li key={index} className='asset-custom-fields_text'>
                        <div className='asset-custom-fields_text'>
                            <p className='asset-custom-fields_label'>{assetCustomField.name}</p>
                            {assetCustomField.type === "Date" ? (
                                <input
                                    type="date"
                                    className='asset-custom-fields_input'
                                    value={inputValues[index]}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                />
                            ) : assetCustomField.type === "Numeric" ? (
                                <input
                                    type="number"
                                    className='asset-custom-fields_input'
                                    value={inputValues[index]}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className='asset-custom-fields_input'
                                    value={inputValues[index]}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                />
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AssetCustomFieldsComponent;