import {CatalogItemApiDto, CatalogItemUpdateApiDto} from "./restModels";
import {MaterialAddableTool, MaterialMeasurementItemStyle} from "./interfaces";
import {EditorTool, MeasurementType} from "../features/project-drawings/features/editor/models/enums";
import LoggerService from "../services/LoggerService";
import {toMaterialAddableTool} from "../utils/PinnedUtils";

export class CatalogItem {
	constructor(public readonly id: number,
		public readonly defaultDrop: number | undefined,
		public readonly groupId: number | undefined,
		public readonly height: number | undefined,
		public readonly laborTime: number | undefined,
		public readonly measurementType: MaterialAddableTool | undefined,
		public readonly name: string,
		public readonly partNumber: string,
		public readonly style: MaterialMeasurementItemStyle | undefined,
		public readonly isVolumeHeightError: boolean | undefined,
		public readonly isAsset: boolean | undefined,
		public readonly assetTypeId: number | undefined,
	) {}

	public static createNew(item: Omit<CatalogItem, "toUpdateJson">): CatalogItem {
		return new CatalogItem(
			item.id,
			item.defaultDrop,
			item.groupId,
			item.height,
			item.laborTime,
			item.measurementType,
			item.name,
			item.partNumber,
			item.style,
			item.isVolumeHeightError,
			item.isAsset,
			item.assetTypeId
		);
	}

	public static fromJson(json: CatalogItemApiDto) {
		let measurementType = undefined;
		if (json.measurementType) {
			measurementType = MeasurementType[json.measurementType];
			if (measurementType === undefined) {
				const error = new Error(`Unknown measurement type: ${json.measurementType}`);
				LoggerService.logError(error);
				throw error;
			}
		}

		const style = json.style
			? JSON.parse(decodeURIComponent(json.style)) as MaterialMeasurementItemStyle
			: undefined

		if (style && !measurementType) {
			const error = new Error(`Missing measurement type but measurement style is present`);
			LoggerService.logError(error);
			throw error;
		}

		return new CatalogItem(
			json.id,
			json.defaultDrop ?? undefined,
			json.groupId,
			json.height ?? undefined,
			json.laborTime,
			measurementType ? toMaterialAddableTool(measurementType) : undefined,
			json.name,
			json.partNumber,
			style ?? undefined,
			undefined,
			json.isAsset,
			json.assetTypeId
		);
	}

	public toUpdateJson(): CatalogItemUpdateApiDto {
		if (this.measurementType === undefined) {
			throw new Error("Measurement type is required in update operation")
		}
		if (this.style === undefined) {
			throw new Error("Style is required in update operation")
		}

		return {
			id: this.id,
			defaultDrop: this.measurementType === EditorTool.LENGTH ? this.defaultDrop : undefined,
			height: this.measurementType === EditorTool.VOLUME ? this.height : undefined,
			measurementType: this.measurementType,
			style: encodeURIComponent(JSON.stringify(this.style))
		}
	}
}
