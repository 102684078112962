import React, {FC, useState} from "react";
import {ImageData} from "../../../../../../base-konva/types";
import ConfirmationModal from "../../../../../../../components/ui/ConfirmationModal";
import Dropdown from "../../../../../../../components/ui/Dropdown";
import DropdownItem from "../../../../../../../components/ui/DropdownItem";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {EditorTool} from "../../../models/enums";
//Reused length panel item styles
import "../length/lengthPanelItem.scss"
import {configActions, selectActiveTool, selectEditorLockState} from "../../config/configSlice";
import {imageActions, selectActiveImageId} from "../../image/imageSlice";
import {useHighlightItem, useHighlightSetter} from "../../../hooks/highlight";
import {IconImageToolSmall} from "../../../icons/side_panel/IconImageToolSmall";
import {get} from "../../../../../../../utils/ClassNameUtils";
import IconVisibleButton from "../../../components/IconVisibleButton";
import {getId} from "../../../../../../../utils";
import IconMaterialAdd from "../../../../../../../components/icons/IconMaterialAdd";

type ImagePanelItemProps = {
	image: ImageData;
	isAssetPlan: boolean
}
export const ImagePanelItem: FC<ImagePanelItemProps> = function({image, isAssetPlan}) {
	const {t} = useTranslation();
	const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const imageActive = useSelector(selectActiveImageId) === image.id && activeTool === EditorTool.IMAGE
	const highlightActions = useHighlightSetter(image.id);
	const editorLocked = useSelector(selectEditorLockState);
	const {isItemHover: isItemBarHover} = useHighlightItem(image.id);

	function handleDelete() {
		dispatch(imageActions.deleteImage({id: image.id}))
	}

	function handleActivate() {
		const activationId = getId();
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.IMAGE, actionId: activationId}))
		dispatch(imageActions.activateImage({id: image.id, actionId: activationId}))
		dispatch(imageActions.setVisibility({id: image.id, visible: true, actionId: activationId}))
	}

	function handleDeactivate() {
		const deactivationId = getId()
		dispatch(imageActions.deactivateImage({actionId: deactivationId}))
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.COUNT, actionId: deactivationId}))
	}

	function toggleActivate() {
		imageActive ? handleDeactivate() : handleActivate()
	}

	function toggleVisibility() {
		dispatch(imageActions.setVisibility({id: image.id, visible: !image.visible}))
	}

	function handleUnassignAsset() {
		// TODO
	}

	const cls = [
		"panel-item",
		imageActive && "is-active",
	].filter(Boolean).join(" ");

	return (
		<>
			<ConfirmationModal isOpen={isConfirmationModalOpen}
							   text={t("editor.deleteItemConfirmationModalQuestion")}
							   onConfirm={handleDelete}
							   onClose={() => setConfirmationModalOpen(false)}/>
			<div className={cls}>
				<div className={get("panel-item_bar", {"is-hover": isItemBarHover})}
					 onMouseEnter={highlightActions.setHighlight}
					 onMouseLeave={highlightActions.clearHighlight}>
					<div className="panel-item_bar_content"  onClick={!isAssetPlan ? toggleActivate : undefined}>
						<div className="panel-item_bar_content_start-icon">
							<IconImageToolSmall isActive={imageActive}/>
						</div>
						<div className="panel-item_bar_content_label">
							{image.name}
						</div>
						<div className="panel-item_bar_content_spacer"/>
					</div>
					<div className={"panel-item_bar_actions"}>
						{!isAssetPlan ? <IconVisibleButton onClick={toggleVisibility} visible={image.visible} isActive={imageActive}/> :
							<IconMaterialAdd></IconMaterialAdd>}
						<Dropdown disabled={editorLocked} isActive={imageActive}>
							<DropdownItem label={t("common.buttons.delete")} onClick={() => isAssetPlan ? handleUnassignAsset() : setConfirmationModalOpen(true)}/>
						</Dropdown>
					</div>
				</div>
			</div>
		</>
	);
}
