import React, {useState} from 'react'
import "./BulkAssetAssignment.scss";
import {
    Button,
    Paper,
    Popper
} from "@mui/material";
import IconMaterialAdd from "../../../../../components/icons/IconMaterialAdd";
import {RemoteSymbol} from "../models/editor";
import SymbolPreviewElement from "../../../../symbols/components/SymbolPreviewElement";
import {useTranslation} from "react-i18next";
import {SymbolType} from "../icons/symbol_pack/default";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import AssetCustomFieldsComponent from "./AssetCustomFields";
import useAssetCustomFields from "../../../../../hooks/useAssetCustomFields";
import {Status} from "../../../../../models/enums";

interface BulkAssetAssignmentProps {
    symbol?: SymbolType
    color?: string
    remoteSymbol?: RemoteSymbol
    id: String;
    materialName: String;
    assetTypeId?: number;
    appendix?: String;
}

const BulkAssetAssignment: React.FC<BulkAssetAssignmentProps> = ({id, materialName , assetTypeId, symbol, color, remoteSymbol, appendix}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [arrowRef] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const [showAssetCustomFields, setShowAssetCustomFields] = useState(false);
    const {loadStatus} = useAssetCustomFields(assetTypeId)


    function toggle() {
        setOpen(!open)
    }

    const close = React.useCallback(() => {
        setOpen(false)
        setShowAssetCustomFields(false)
    }, [])

    const handleCreateNewClick = () => {
        setShowAssetCustomFields(true)
    }

    function renderAppendix() {
        if (color) {
            return <div style={{display: "inline"}}>(&times;{appendix} items)</div>
        } else {
            return <div style={{display: "inline"}}>({appendix})</div>}
    }

    return (
        <>
            <div ref={setAnchorEl}  onClick={toggle}>
                <IconMaterialAdd/>
            </div>
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={'right-start'}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                <ClickAwayListener onClickAway={close}>
                    <Paper className='bulk-assignment'>
                        <div className='bulk-assignment_title'>
                            <div className='bulk-assignment_symbol'>
                                {color ? <SymbolPreviewElement color={color}
                                                                symbol={symbol}
                                                                remoteSymbol={remoteSymbol}/> : null}
                            </div>
                            <div className='bulk-assignment_name'>
                                {t("common.assetCreation.bulkAssetAssignment")} {renderAppendix()}
                            </div>
                        </div>
                        <div className="bulk-assignment_content_label">
                            {materialName}
                        </div>
                        <div className='bulk-assignment_arrow'></div>
                        <div className='bulk-assignment_content'>
                            {showAssetCustomFields ? (
                                <AssetCustomFieldsComponent
                                    assetTypeId={assetTypeId}
                                />
                            ) : (
                                <>
                                    <Button className='bulk-assignment_button' onClick={handleCreateNewClick}>
                                        <span style= {{fontWeight: "bold"}}>{t("common.assetCreation.create")}</span>
                                        <span style= {{fontWeight: "normal"}}>{t("common.assetCreation.new") }</span>
                                    </Button>
                                    <Button className='bulk-assignment_button'>
                                        <span style= {{fontWeight: "normal"}}>{t("common.assetCreation.mapTo")}</span>
                                        <span style= {{fontWeight: "bold"}}>{t("common.assetCreation.existing")}</span>
                                    </Button>
                                </>
                            )}
                        </div>
                        <div className='bulk-assignment_close'>
                            {showAssetCustomFields ? (
                                <>
                                    {loadStatus !== Status.NO_DATA && (
                                        <div className='asset-custom-fields_button asset-custom-fields_continue' onClick={close}>
                                            Continue
                                        </div>
                                    )}
                                    <div className='asset-custom-fields_button asset-custom-fields_cancel' onClick={close}>
                                        Cancel
                                    </div>
                                </>
                            ) : (
                                <div className='bulk-assignment_close_button' onClick={close}>
                                    <div style= {{color: "#4B4747FF", textAlign: "center"}}>&#x2715;</div>
                                </div>
                            )}
                        </div>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
};

export default BulkAssetAssignment;
