import {DrawingType, Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import NotificationService from "../../../services/NotificationService";
import LoggerService from "../../../services/LoggerService";
import {useTranslation} from "react-i18next";
import {OnSuccess} from "../../../utils/CallbackUtils";
import {SelectedDrawingVersion} from "../features/drawing-exporter";
import {useDrawingVersionsSummaryExporter} from "../features/sync-materials/hooks/useDrawingVersionsSummaryExporter";
import {useProjectDetailContext} from "../providers/ProjectDetailProvider";
import DrawingData from "../../../models/DrawingData";

enum DrawingCompletionErrors {
	NOT_LATEST = "NOT_LATEST",
	LOCK_TAKE_OFF = "LOCK_TAKE_OFF",
	LOCK_AS_BUILT = "LOCK_AS_BUILT",
	MISSING_ATTACHMENT = "MISSING_ATTACHMENT",
	DEFAULT = "DEFAULT"
}

enum RevertDrawingErrors {
	NOT_LATEST = "NOT_LATEST",
	NOT_COMPLETED = "NOT_COMPLETED",
	LOCK_TAKE_OFF = "LOCK_TAKE_OFF",
	LOCK_AS_BUILT = "LOCK_AS_BUILT",
	DEFAULT = "DEFAULT"
}

function useVersionComplete(drawingType: DrawingType) {
	const {t} = useTranslation();
	const {projectDetails, fitTimesData} = useProjectDetailContext()
	const {fitTimes, loadStatus: fitTimesLoadStatus} = fitTimesData;
	const drawingVersionsSummaryExporter = useDrawingVersionsSummaryExporter()

	const [saveStatus, setSaveStatus] = useState(Status.IDLE)

	const translations = {
		"completionSuccess": {
			[DrawingType.TAKE_OFF]: t(`projects.details.tree.completeRevertModal.completeSuccessDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.tree.completeRevertModal.completeSuccessDesc.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.tree.completeRevertModal.completeSuccessDesc.asset_plan`)
		},
		"completionErrors": {
			[DrawingCompletionErrors.NOT_LATEST]: t(`projects.details.tree.completeRevertModal.completeErrorDescLatest`),
			[DrawingCompletionErrors.LOCK_TAKE_OFF]:
				t(`projects.details.tree.completeRevertModal.completeErrorDescLock.take_off`),
			[DrawingCompletionErrors.LOCK_AS_BUILT]:
				t(`projects.details.tree.completeRevertModal.completeErrorDescLock.as_built`),
			[DrawingCompletionErrors.MISSING_ATTACHMENT]:
				t(`projects.details.tree.completeRevertModal.completeErrorDescMissingAttachment`),
			[DrawingCompletionErrors.DEFAULT]: t(`projects.details.tree.completeRevertModal.completeErrorDesc`)
		},
		"revertSuccess": {
			[DrawingType.TAKE_OFF]: t(`projects.details.tree.completeRevertModal.revertSuccessDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.tree.completeRevertModal.revertSuccessDesc.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.tree.completeRevertModal.revertSuccessDesc.asset_plan`)
		},
		"revertErrors": {
			[RevertDrawingErrors.NOT_LATEST]: t(`projects.details.tree.completeRevertModal.revertErrorDescLatest`),
			[RevertDrawingErrors.NOT_COMPLETED]: t(`projects.details.tree.completeRevertModal.revertErrorDescReverted`),
			[RevertDrawingErrors.LOCK_TAKE_OFF]: t(`projects.details.tree.completeRevertModal.revertErrorDescLock.take_off`),
			[RevertDrawingErrors.LOCK_AS_BUILT]: t(`projects.details.tree.completeRevertModal.revertErrorDescLock.as_built`),
			[RevertDrawingErrors.DEFAULT]: t(`projects.details.tree.completeRevertModal.revertErrorDesc`),
		}
	}

	async function completeDrawingVersion(selectedDrawingVersion: SelectedDrawingVersion, onSuccess: OnSuccess) {
		setSaveStatus(Status.LOADING)
		try {
			const completedDrawingData = await apiInstance.drawingsApi.completeDrawingVersion(
				selectedDrawingVersion.selectedVersionId
			);
			if (completedDrawingData && completedDrawingData.data) {
				if (drawingType === DrawingType.AS_BUILT) {
					await exportDrawingVersionSummary(completedDrawingData)
				}

				setSaveStatus(Status.SUCCESS)
				NotificationService.successNotification(
					t("common.success"),
					translations["completionSuccess"][drawingType]
				)
				onSuccess();
			}
		}
		catch (e) {
			handleError(e)
		}

		async function exportDrawingVersionSummary(drawingData: DrawingData) {
			const projectId = projectDetails.project?.id
			if (!projectId) {
				throw new Error("Project details projectId is undefined")
			}
			if (fitTimesLoadStatus === Status.ERROR) {
				throw new Error("Fit times fetch failed. Cannot proceed with export")
			}
			const sections = await apiInstance.projectsApi.fetchTree(projectId)
			await new Promise<void>((resolve, reject) => {
				drawingVersionsSummaryExporter.exportDrawingVersionsSummary(
					projectId,
					[{
						versionId: drawingData.id,
						drawingType: selectedDrawingVersion.drawingType
					}],
					fitTimes, sections, resolve, reject
				)
			})
		}

		function handleError(err: any) {
			let errorKey: DrawingCompletionErrors;
			if (err?.response?.data?.exception?.includes("DrawingVersionIsNotTheLatestException")) {
				errorKey = DrawingCompletionErrors.NOT_LATEST
			}
			else if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
				if (drawingType === DrawingType.TAKE_OFF) {
					errorKey = DrawingCompletionErrors.LOCK_TAKE_OFF
				}
				else {
					errorKey = DrawingCompletionErrors.LOCK_AS_BUILT
				}
			}
			else if (err?.response?.data?.exception?.includes("MissingDrawingVersionAttachmentException")) {
				errorKey = DrawingCompletionErrors.MISSING_ATTACHMENT
			}
			else {
				errorKey = DrawingCompletionErrors.DEFAULT
			}
			setSaveStatus(Status.ERROR)
			NotificationService.errorNotification(t("common.error"), translations["completionErrors"][errorKey]);
			LoggerService.logError(err);
		}
	}

	function revertDrawingVersionCompletion(drawingVersionId: string, onSuccess: OnSuccess) {
		setSaveStatus(Status.LOADING)
		apiInstance.drawingsApi.revertDrawingVersion(
			drawingVersionId
		).then(() => {
			setSaveStatus(Status.SUCCESS);
			NotificationService.successNotification(t("common.success"),
				translations["revertSuccess"][drawingType]);
			onSuccess();
		}).catch(err => {
			setSaveStatus(Status.ERROR)
			let errorKey: RevertDrawingErrors;
			if (err?.response?.data?.exception?.includes("DrawingVersionIsNotTheLatestException")) {
				errorKey = RevertDrawingErrors.NOT_LATEST
			}
			else if (err?.response?.data?.exception?.includes("DrawingVersionIsNotTheCompletedException")) {
				errorKey = RevertDrawingErrors.NOT_COMPLETED
			}
			else if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
				if (drawingType === DrawingType.TAKE_OFF) {
					errorKey = RevertDrawingErrors.LOCK_TAKE_OFF
				}
				else {
					errorKey = RevertDrawingErrors.LOCK_AS_BUILT
				}
			}
			else {
				errorKey = RevertDrawingErrors.DEFAULT
			}

			NotificationService.errorNotification(
				t("common.error"),
				translations["revertErrors"][errorKey]
			);
			LoggerService.logError(err);
		})
	}

	return {saveStatus, completeDrawingVersion, revertDrawingVersionCompletion}
}

export default useVersionComplete;