import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import IconArrowSmall from "../../../icons/IconArrowSmall";
import Dropdown from "../../../../../../../components/ui/Dropdown";
import DropdownItem from "../../../../../../../components/ui/DropdownItem";
import {useTranslation} from "react-i18next";
//Reused length panel item styles
import "../length/lengthPanelItem.scss"
import ConfirmationModal from "../../../../../../../components/ui/ConfirmationModal";
import {selectActiveTextGroupId, textActions} from "../../text/textSlice";
import TextItemPresenter from "../../../components/TextItemPresenter";
import IconTextToolSmall from "../../../icons/side_panel/IconTextToolSmall";
import {configActions, selectActiveTool, selectEditorLockState} from "../../config/configSlice";
import {EditorTool} from "../../../models/enums";
import {useHighlightSetter} from "../../../hooks/highlight";
import {selectHighlightState} from "../../view/viewSlice";
import {get} from "../../../../../../../utils/ClassNameUtils";
import IconVisibleButton from "../../../components/IconVisibleButton";
import {getId} from "../../../../../../../utils";
import {TextGroup} from "../../../../../../base-konva/types";
import IconMaterialAdd from "../../../../../../../components/icons/IconMaterialAdd";

interface TextTabItemProps {
	textGroup: TextGroup;
	isAssetPlan: boolean
}

const TextPanelItem: React.FC<TextTabItemProps> = ({textGroup, isAssetPlan}) => {
	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const editorLocked = useSelector(selectEditorLockState);
	const textGroupActive = useSelector(selectActiveTextGroupId) === textGroup.id && activeTool === EditorTool.TEXT;
	const {t} = useTranslation();

	const [isExpanded, setIsExpanded] = useState(false);
	const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const highlightActions = useHighlightSetter(textGroup.id);

	const {itemId: highlightedItemId} = useSelector(selectHighlightState);
	const isItemBarHover =
		textGroup.id === highlightedItemId ||
		textGroup.textItems.some(textItem => textItem.id === highlightedItemId)

	function toggleExpand() {
		setIsExpanded(!isExpanded);
	}

	const cls = [
		"panel-item",
		(activeTool === EditorTool.TEXT && textGroupActive) && "is-active",
		isExpanded && "is-expanded",
	].filter(Boolean).join(" ");

	function handleActivate() {
		const activationId = getId();
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.TEXT, actionId: activationId}))
		dispatch(textActions.activateTextGroup({id: textGroup.id, actionId: activationId}))
		dispatch(textActions.setVisibility({id: textGroup.id, visible: true, actionId: activationId}))
	}

	function handleDeactivate() {
		const deactivationId = getId();
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.COUNT, actionId: deactivationId}))
		dispatch(textActions.deactivateTextGroup({actionId: deactivationId}))
	}

	function handleDelete() {
		dispatch(textActions.deleteTextGroup({id: textGroup.id}));
	}

	function handleUnassignAsset() {
		// TODO
	}

	function toggleActivate() {
		textGroupActive ? handleDeactivate() : handleActivate()
	}

	function toggleVisibility() {
		dispatch(textActions.setVisibility({id: textGroup.id, visible: !textGroup.visible}))
	}

	return (
		<>
			<ConfirmationModal isOpen={isConfirmationModalOpen}
							   text={t("editor.deleteItemConfirmationModalQuestion")}
							   onConfirm={handleDelete}
							   onClose={() => setConfirmationModalOpen(false)}/>
			<div className={cls}>
				<div className={get("panel-item_bar", {"is-hover": isItemBarHover})}
					 onMouseEnter={highlightActions.setHighlight}
					 onMouseLeave={highlightActions.clearHighlight}>
					<div className={"panel-item_bar_icon"} onClick={toggleExpand}>
						<IconArrowSmall isActive={textGroupActive}/>
					</div>
					<div className="panel-item_bar_content"  onClick={!isAssetPlan ? toggleActivate : undefined}>
						<div className="panel-item_bar_content_start-icon">
							<IconTextToolSmall isActive={textGroupActive}/>
						</div>
						<div className="panel-item_bar_content_label">
							{textGroup.name}
						</div>
						<div className="panel-item_bar_content_spacer"/>
					</div>
					<div className={"panel-item_bar_props"}>&times;{textGroup.textItems.length}</div>
					<div className={"panel-item_bar_actions"}>
						{!isAssetPlan ? <IconVisibleButton onClick={toggleVisibility} visible={textGroup.visible} isActive={textGroupActive}/> :
							<IconMaterialAdd></IconMaterialAdd>}
						<Dropdown disabled={editorLocked} isActive={textGroupActive}>
							<DropdownItem label={t("common.buttons.delete")} onClick={() => isAssetPlan ? handleUnassignAsset() : setConfirmationModalOpen(true)}/>
						</Dropdown>
					</div>
				</div>
				<div className={"panel-item_content"}>
					{
						textGroup.textItems.filter(item => item.text.length !== 0).map(item => (
							<TextItemPresenter key={item.id} parentId={textGroup.id}
											   item={item} disabled={editorLocked}
											   setItemVisibility={textActions.setItemVisibility}
											   onDelete={textActions.deleteTextItem}
											   isAssetPlan={isAssetPlan}/>
						))
					}
				</div>
			</div>
		</>
	);
};

export default TextPanelItem
