import {useEffect, useState} from "react";
import {Status} from "../models/enums";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../api/api";
import LoggerService from "../services/LoggerService";
import NotificationService from "../services/NotificationService"
import AssetCustomField from "../models/AssetCustomField";

function useAssetCustomFields(assetTypeId: number | undefined) {
    const {t} = useTranslation();
    const [loadStatus, setLoadStatus] = useState(Status.IDLE);
    const [assetCustomFields, setAssetCustomFields] = useState<AssetCustomField[]>([]);

    useEffect(() => {
        async function loadAssetCustomFields() {
            setLoadStatus(Status.LOADING);

            try {
                if (assetTypeId) {
                    const fields =
                        await apiInstance.assetCustomFieldsApi.fetchAssetCustomFields(assetTypeId);

                    if (fields.length === 0) {
                        setLoadStatus(Status.NO_DATA)
                        return;
                    }

                    setAssetCustomFields(fields);
                    setLoadStatus(Status.SUCCESS);
                }
            } catch (err) {
                setLoadStatus(Status.ERROR);
                LoggerService.logError(err);
                NotificationService.errorNotification(
                    t("common.error"),
                    t("projects.summary.itemsTable.updateAssetPlan.loadErrorDesc")
                );
            }
        }

        loadAssetCustomFields();
    }, [assetTypeId, t]);

    return { assetCustomFields, loadStatus };
}

export default useAssetCustomFields;