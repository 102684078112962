import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {apiInstance} from "../../../../../../api/api";
import {Status} from "../../../../../../models/enums";
import LoggerService from "../../../../../../services/LoggerService";
import NotificationService from "../../../../../../services/NotificationService";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {PinnedMeasurement} from "../../../../../../models/PinnedMeasurement";
import {MaterialType, MeasurementType} from "../../models/enums";
import {MaterialMeasurementItemStyle} from "../../../../../../models/interfaces";

export interface UsePinnedMeasurementsHook {
	loadPinnedMeasurements: React.MutableRefObject<() => void>;
	pinnedMeasurements: PinnedMeasurement[],
	createPinnedMeasurement: (materialId: number | undefined,
		materialType: MaterialType,
		measurementType: MeasurementType,
		name: string | undefined,
		height: number | undefined,
		defaultDrop: number | undefined,
		style: MaterialMeasurementItemStyle,
		isAsset: boolean | undefined,
		assetTypeId: number | undefined) => void,
	updatePinnedMeasurement: (id: string,
		materialId: number | undefined,
		materialType: MaterialType,
		measurementType: MeasurementType,
		name: string | undefined,
		height: number | undefined,
		defaultDrop: number | undefined,
		style: MaterialMeasurementItemStyle,
		isAsset: boolean | undefined,
		assetTypeId: number | undefined) => void,
	deletePinnedMeasurement: (id: string) => void,
	loadStatus: Status,
	saveStatus: Status
}

function usePinnedMeasurements(): UsePinnedMeasurementsHook {
	const {t} = useTranslation();

	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const [saveStatus, setSaveStatus] = useState(Status.IDLE);
	const [pinnedMeasurements, setPinnedMeasurements] = useState<PinnedMeasurement[]>([]);

	const loadPinnedMeasurementsFunc = () => {
		setLoadStatus(Status.LOADING);
		apiInstance.pinnedMeasurementsApi.fetchPinnedMeasurements()
			.then(pinnedItems => {
				setPinnedMeasurements(pinnedItems);
				setLoadStatus(Status.SUCCESS);
			})
			.catch(err => {
				setLoadStatus(Status.ERROR);
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"),
					t("editor.panel.pinnedItems.fetchPinnedMeasurementsErrorDesc"));
			});
	};

	const createPinnedMeasurement = (materialId: number | undefined,
		materialType: MaterialType,
		measurementType: MeasurementType,
		name: string | undefined,
		height: number | undefined,
		defaultDrop: number | undefined,
		style: MaterialMeasurementItemStyle,
		isAsset: boolean | undefined,
		assetTypeId: number | undefined,) => {
		setSaveStatus(Status.LOADING);
		const stringifiedStyle = encodeURIComponent(JSON.stringify(style));
		apiInstance.pinnedMeasurementsApi.postPinnedMeasurement(materialId, materialType, measurementType, name, height,
			defaultDrop, stringifiedStyle, isAsset, assetTypeId)
			.then(() => {
				loadPinnedMeasurementsFunc();
				NotificationService.successNotification(t("common.success"),
					t("editor.panel.pinnedItems.createPinnedMeasurementSuccess"));
				setSaveStatus(Status.SUCCESS);
			})
			.catch(err => {
				setSaveStatus(Status.ERROR);
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"),
					t("editor.panel.pinnedItems.createPinnedMeasurementErrorDesc"));
			});
	};

	const updatePinnedMeasurement = (
		pinnedMeasurementId: string,
		materialId: number | undefined,
		materialType: MaterialType,
		measurementType: MeasurementType,
		name: string | undefined,
		height: number | undefined,
		defaultDrop: number | undefined,
		style: MaterialMeasurementItemStyle,
		isAsset: boolean | undefined,
		assetTypeId: number | undefined
	) => {
		setSaveStatus(Status.LOADING);
		const stringifiedStyle = encodeURIComponent(JSON.stringify(style));
		apiInstance.pinnedMeasurementsApi.putPinnedMeasurement(
			pinnedMeasurementId, materialId, materialType,
			measurementType, name, height, defaultDrop, stringifiedStyle, isAsset, assetTypeId
		).then(() => {
			loadPinnedMeasurementsFunc();
			NotificationService.successNotification(t("common.success"),
				t("editor.panel.pinnedItems.updatePinnedMeasurementSuccess"));
			setSaveStatus(Status.SUCCESS);
		}).catch(err => {
			setSaveStatus(Status.ERROR);
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"),
				t("editor.panel.pinnedItems.updatePinnedMeasurementsErrorDesc"));
		});
	};

	const deletePinnedMeasurement = (pinnedMeasurementId: string) => {
		setSaveStatus(Status.LOADING);
		apiInstance.pinnedMeasurementsApi.deletePinnedMeasurement(pinnedMeasurementId)
			.then(() => {
				loadPinnedMeasurementsFunc();
				setSaveStatus(Status.SUCCESS);
				NotificationService.successNotification(t("common.success"),
					t("editor.panel.pinnedItems.deletePinnedMeasurementSuccess"));
			})
			.catch(err => {
				setSaveStatus(Status.ERROR);
				NotificationService.errorNotification(t("common.error"),
					t("editor.panel.pinnedItems.deletePinnedMeasurementsErrorDesc"));
				LoggerService.logError(err);
			})
	};

	const loadPinnedMeasurements = useUpdatedRef(loadPinnedMeasurementsFunc);

	return {
		loadPinnedMeasurements,
		pinnedMeasurements,
		createPinnedMeasurement,
		updatePinnedMeasurement,
		deletePinnedMeasurement,
		loadStatus,
		saveStatus
	};
}

export default usePinnedMeasurements;
