import React from "react";
import {selectLengths} from "../length/lengthSlice";
import {useSelector} from "react-redux";
import LengthPanelItem from "./length/LengthPanelItem";
import EditorPanelTabContent from "./EditorPanelTabContent";
import {isAssetPlanProps} from "../../constants";

const LengthPanelTab: React.FC<isAssetPlanProps> = ({isAssetPlan}) => {
	const lengths = useSelector(selectLengths);

	return (
		<>
			<EditorPanelTabContent>
				{lengths.map(length => <LengthPanelItem key={length.id} length={length} isAssetPlan={isAssetPlan}/>)}
			</EditorPanelTabContent>
		</>
	)
};

export default LengthPanelTab
