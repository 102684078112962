import {DrawingType, Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import {useTranslation} from "react-i18next";
import {PageScaleRatio} from "../../../models/DrawingDetails";
import NotificationService from "../../../services/NotificationService";
import {LockError} from "../features/editor/models/enums";

interface UseDrawingScaleHook {
	saveScale: (
		drawingId: string | undefined,
		planScale: number,
		pageScaleRatio?: PageScaleRatio
	) => void,
	saveStatus: Status,
}

function useDrawingScale(drawingType: DrawingType): UseDrawingScaleHook {

	const [saveStatus, setSaveStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	const scaleErrorTranslations = {
		[LockError.LOCK]: {
			[DrawingType.TAKE_OFF]: t(`editor.scale.setScaleErrorDescLock.take_off`),
			[DrawingType.AS_BUILT]: t(`editor.scale.setScaleErrorDescLock.as_built`),
			[DrawingType.ASSET_PLAN]: t(`editor.scale.setScaleErrorDescLock.asset_plan`)
		},
		[LockError.DEFAULT]: {
			[DrawingType.TAKE_OFF]: t(`editor.scale.setScaleErrorDesc`),
			[DrawingType.AS_BUILT]: t(`editor.scale.setScaleErrorDesc`),
			[DrawingType.ASSET_PLAN]: t(`editor.scale.setScaleErrorDesc`)
		}
	}

	function handleScaleError(err: any) {
		setSaveStatus(Status.ERROR);
		LoggerService.logError(err);
		let errorKey: LockError;
		if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
			errorKey = LockError.LOCK
		}
		else {
			errorKey = LockError.DEFAULT
		}
		NotificationService.errorNotification(t("common.error"), scaleErrorTranslations[errorKey][drawingType]);
	}

	function saveScale(
		drawingId: string | undefined, planScale: number,  pageScaleRatio?: PageScaleRatio
	) {
		setSaveStatus(Status.LOADING);
		if (drawingId !== undefined) {
			apiInstance.drawingsApi.putDrawingScale(
				drawingId, planScale, pageScaleRatio?.toJson()
			).then(() =>
				setSaveStatus(Status.SUCCESS)
			).catch(handleScaleError)
		}
		else {
			// ScaleSetProvider must have drawingId set
			handleScaleError(new Error("drawingId is undefined"))
		}
	}

	return {saveScale, saveStatus}
}

export default useDrawingScale;