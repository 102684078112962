import React, {useState} from "react";
import "../../../components/groupItemPresenter.scss";
import {useTranslation} from "react-i18next";
import {LengthMeasurementDrop} from "../../../models/editor";
import {useUserSettingsDataContext} from "../../../../../../../providers/UserSettingsProvider";
import {formatLength} from "../../../../../../../utils/TextUtils";
import SetNumberValueModal from "./SetNumberValueModal";

interface DropItemProps {
	drop: LengthMeasurementDrop,
	onDelete: (id: string) => void,
	onChange: (id: string, value: number) => void,
	disabled?: boolean,
	isAssetPlan: boolean
}

const DropItem: React.FC<DropItemProps> = ({drop, onDelete, onChange, disabled, isAssetPlan}) => {

	const {settings} = useUserSettingsDataContext();
	const [isOpen, setIsOpen] = useState(false);

	const {t} = useTranslation();

	function handleSave(value: number) {
		onChange(drop.id, value);
		setIsOpen(false);
	}

	function handleDelete() {
		!disabled && onDelete(drop.id)
	}

	const propsCls = [
		"group-item-presenter_props",
		!disabled && "is-clickable",
		disabled && "disabled"
	].filter(Boolean).join(" ");

	const actionsButtonCls = [
		"group-item-presenter_actions_button",
		disabled && "disabled"
	].filter(Boolean).join(" ");

	return (
		<>
			<SetNumberValueModal isOpen={isOpen}
								 initialValue={drop.value}
								 onSave={handleSave}
								 onClose={() => setIsOpen(false)}
								 title={t("editor.length.setDropValueModal.title")}
								 inputLabel={t("editor.length.setDropValueModal.inputLabel")}/>
			<div className={"group-item-presenter"}>
				<div className={"group-item-presenter_label"}>{t("editor.length.dropItemName")}</div>
				<div className={propsCls}
					 onClick={() => !disabled && setIsOpen(true)}>
					{formatLength(drop.value, settings?.measurementSystem)}
				</div>
				<div className={"group-item-presenter_actions"}>
					<div className={actionsButtonCls} onClick={handleDelete}>
						&times;
					</div>
				</div>
			</div>
		</>
	);
};

export default DropItem
