import React from "react";
import Button from "../../../../../../components/ui/Button";
import {DrawingType, Status} from "../../../../../../models/enums";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import useCreateAssetPlanFromAsBuilt from "../../../../../project-details/hooks/useCreateAssetPlanFromAsBuilt";
import ConfigHelper from "../config/ConfigHelper";
import {INITIAL_VERSION_ID} from "../../constants";
import {useSelector} from "react-redux";
import {selectScaleConfig} from "../scale/scaleSlice";
import {useProjectDetailContext} from "../../../../../project-details/providers/ProjectDetailProvider";

const CreateAssetPlan = ({disabled}: { disabled?: boolean }) => {
	const {t} = useTranslation();
	const scaleConfig = useSelector(selectScaleConfig);
	const {isJobProject} = useProjectDetailContext()
	const {
		projectId,
		drawingId,
		versionId,
		costCenterId,
		sectionId
	} = useParams<{
		projectId: string,
		sectionId: string,
		costCenterId: string,
		drawingId: string,
		versionId: string
	}>();
	const {
		drawingData: {loadStatus: drawingDetailsLoadStatus, drawingDetails}
	} = useDrawingDetailsDataContext();
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	const {createAssetPlan, saveStatus} = useCreateAssetPlanFromAsBuilt(
		projectId, sectionId, costCenterId, drawingId, versionId, drawingDetails?.name ?? "Asset Plan"
	);

	const handleClick = () => createAssetPlan()

	return (isJobProject && drawingType === DrawingType.AS_BUILT) ? (
		<Button variant={"optional"} label={t("projects.details.createNewDrawingModal.title.asset_plan")}
				onClick={handleClick}
				disabled={!ConfigHelper.isScaleSet(scaleConfig)
					|| saveStatus === Status.LOADING
					|| disabled
					|| versionId === INITIAL_VERSION_ID
					|| drawingDetailsLoadStatus !== Status.SUCCESS}
		/>
	) : null;
};

export default CreateAssetPlan;
