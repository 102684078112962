import {DrawingType, Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {deserializeDrawingSchema, serializeRootState} from "../features/editor/features/serialization/SerializationHelper";
import {TFunction, useTranslation} from "react-i18next";
import AttachmentApi from "../../../api/AttachmentApi";
import {resetStore, RootState} from "../features/editor/editorStore";
import useDrawingLockUnlock from "../../project-details/hooks/useDrawingLockUnlock";
import {lengthActions} from "../features/editor/features/length/lengthSlice";
import {areaActions} from "../features/editor/features/area/areaSlice";
import {useDispatch, useStore} from "react-redux";
import {OnSuccessData} from "../../../utils/CallbackUtils";
import DrawingDetails from "../../../models/DrawingDetails";
import UserSettings from "../../../models/UserSettings";
import {DEFAULT_BLANK_STAGE_HEIGHT, DEFAULT_BLANK_STAGE_WIDTH, INITIAL_VERSION_ID} from "../features/editor/constants";
import {StageConfig} from "../features/editor/models/editor";
import {getScaleInfoState} from "../features/editor/features/scaleInfo/scaleInfoUtils";
import {viewActions} from "../features/editor/features/view/viewSlice";
import {DrawingVersion} from "../../../models/DrawingVersion";
import {DrawingErrorTypes} from "../features/editor/models/enums";
import {useLocation} from "react-router-dom";
import {initialScaleInfoState} from "../../base-konva/constants";
import {pages, useNavigator} from "../../navigator";
import {loadImage} from "./useImage";

const translations = (t: TFunction) => ({
	"loadDrawingErrors": {
		[DrawingType.TAKE_OFF]: t(`editor.loadDrawingErrorDescLock.take_off`),
		[DrawingType.AS_BUILT]: t(`editor.loadDrawingErrorDescLock.as_built`),
		[DrawingType.ASSET_PLAN]: t(`editor.loadDrawingErrorDescLock.asset_plan`)
	},
	"saveDrawingError": {
		[DrawingErrorTypes.DEFAULT]: {
			[DrawingType.TAKE_OFF]: t(`editor.saveDrawingErrorDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`editor.saveDrawingErrorDesc.as_built`),
			[DrawingType.ASSET_PLAN]: t(`editor.saveDrawingErrorDesc.asset_plan`)
		},
		[DrawingErrorTypes.VERSION_LOCK]: {
			[DrawingType.TAKE_OFF]: t(`editor.saveDrawingErrorDescVersionLock.take_off`),
			[DrawingType.AS_BUILT]: t(`editor.saveDrawingErrorDescVersionLock.as_built`),
			[DrawingType.ASSET_PLAN]: t(`editor.saveDrawingErrorDescVersionLock.asset_plan`)
		},
		[DrawingErrorTypes.DRAWING_LOCK]: {
			[DrawingType.TAKE_OFF]: t(`editor.saveDrawingErrorDescDrawingLock.take_off`),
			[DrawingType.AS_BUILT]: t(`editor.saveDrawingErrorDescDrawingLock.as_built`),
			[DrawingType.ASSET_PLAN]: t(`editor.saveDrawingErrorDescDrawingLock.asset_plan`)
		}
	},
	"saveDrawingSuccess": {
		[DrawingType.TAKE_OFF]: t("editor.saveDrawingSuccessDesc.take_off"),
		[DrawingType.AS_BUILT]: t("editor.saveDrawingSuccessDesc.as_built"),
		[DrawingType.ASSET_PLAN]: t("editor.saveDrawingSuccessDesc.asset_plan")
	}
})

function useDrawingData(
	projectId: string,
	drawingId: string,
	versionId: string,
	costCenterId: string,
	sectionId: string,
	silent: boolean = false,
	lockDrawingOnLoad: boolean = false
) {

	const {navigateTo} = useNavigator()
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";

	const [loadStatus, setLoadStatus] = useState(Status.IDLE)
	const [image, setImage] = useState<HTMLImageElement | undefined>();
	const [drawingDetails, setDrawingDetails] = useState<DrawingDetails | undefined>(undefined);
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	const {lockDrawing} = useDrawingLockUnlock(drawingId, drawingType);
	const store = useStore<RootState>()
	const {t} = useTranslation();

	function handleFetchError(err: any) {
		setLoadStatus(Status.ERROR);
		LoggerService.logError(err);
		const exception = err?.response?.data?.exception;
		if (exception?.includes("DrawingNotFoundException") || exception?.includes("DrawingVersionNotFoundException")) {
			navigateTo(pages.projectDetailsPage(type, projectId), {replace: true})
			const takeOffErrorKey = "projects.details.noDrawingMessage.take_off";
			const asBuiltErrorKey = "projects.details.noDrawingMessage.as_built";
			const assetPlanErrorKey = "projects.details.noDrawingMessage.asset_plan"
			NotificationService.errorNotification(t("common.error"),
				t(drawingType === DrawingType.TAKE_OFF ?
					takeOffErrorKey : drawingType === DrawingType.ASSET_PLAN ?
						assetPlanErrorKey : asBuiltErrorKey))
		}
		else if (!silent) {
			const takeOffErrorKey = "editor.loadDrawingErrorDesc.take_off";
			const asBuiltErrorKey = "editor.loadDrawingErrorDesc.as_built";
			const assetPlanErrorKey = "editor.loadDrawingErrorDesc.asset_plan";
			NotificationService.errorNotification(t("common.error"),
				t(drawingType === DrawingType.TAKE_OFF ?
					takeOffErrorKey : drawingType === DrawingType.ASSET_PLAN ?
						assetPlanErrorKey : asBuiltErrorKey))
		}
	}

	function handleFetchSuccess(img: HTMLImageElement | undefined, drawingDetails: DrawingDetails) {
		setImage(img);
		setLoadStatus(Status.SUCCESS);
		setDrawingDetails(drawingDetails);
	}

	function loadDrawing(settings: UserSettings, onSuccess?: OnSuccessData<DrawingDetails>) {
		setLoadStatus(Status.LOADING)
		apiInstance.drawingsApi.fetchDrawingDetails(drawingId)
			.then(async (drawingDetails) => {
				if (lockDrawingOnLoad) {
					if (drawingDetails.locked) {
						NotificationService.errorNotification(
							t("common.error"),
							translations(t)["loadDrawingErrors"][drawingType]
						);
						navigateTo(pages.projectDetailsPage(type, projectId))
						return;
					}
					else {
						lockDrawing();
					}
				}
				try {
					let img: HTMLImageElement | undefined = undefined;
					if (drawingDetails.planId && drawingDetails.planFilename) {
						const url = AttachmentApi.getAttachmentDownloadUrl(drawingDetails.planId, drawingDetails.planFilename);
						img = await loadImage(url, "anonymous");
					}
					if (versionId === INITIAL_VERSION_ID) {
						const stageHeight = img?.height ?? DEFAULT_BLANK_STAGE_HEIGHT;
						const stageWidth = img?.width ?? DEFAULT_BLANK_STAGE_WIDTH;
						const stageConfig: StageConfig = {
							backgroundImagePosition: {x: 0, y: 0},
							width: stageWidth,
							height: stageHeight
						}
						let unitFactor: number | null = null;
						if (drawingDetails.planScale) {
							const newScaleInfoState = getScaleInfoState(
								drawingDetails.planScale,
								initialScaleInfoState,
								settings,
								stageConfig
							)
							unitFactor = newScaleInfoState.unitFactor
						}
						const exportInProgress = store.getState().view.exportInProgress
						resetStore({
							store,
							stageConfig,
							planScale: drawingDetails.planScale ?? undefined,
							pageScaleRatio: drawingDetails.pageScaleRatio ?? undefined,
							unitFactor: unitFactor ?? undefined,
							postResetInit: initDispatch => {
								initDispatch(viewActions.setRemoteContentLoadedInfo({allItemsLoaded: true, loadedInfos: []}))
								initDispatch(viewActions.setExportInProgress(exportInProgress))
							}
						});
					}
					else {
						const drawingData = await apiInstance.drawingsApi.fetchDrawingData(versionId);
						if (drawingData.data) {
							deserializeDrawingSchema(
								store,
								drawingData.data,
								drawingData.measurements,
								drawingData.locked,
								drawingData.planScale
							);
						}
					}
					handleFetchSuccess(img, drawingDetails);
					onSuccess?.(drawingDetails)
				}
				catch (error) {
					handleFetchError(error);
				}
			})
			.catch(handleFetchError)
	}

	function forceDrawingUpdate() {
		setLoadStatus(Status.LOADING);
		apiInstance.drawingsApi.fetchDrawingDetails(drawingId).then(details => {
			setDrawingDetails(details);
			setLoadStatus(Status.SUCCESS)
		}).catch((err) => {
			setDrawingDetails(undefined)
			setLoadStatus(Status.ERROR);
			LoggerService.logError(err);
			if (!err?.response?.data?.exception?.includes("DrawingNotFoundException")) {
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.details.fetchDrawingDetailsErrorDesc")
				)
			}
		})
	}

	return {loadStatus, loadDrawing, image, drawingDetails, forceDrawingUpdate}
}

function useSaveDrawing() {
	const {t} = useTranslation();
	const dispatch = useDispatch()
	const store = useStore<RootState>()
	const [saveStatus, setSaveStatus] = useState(Status.IDLE)

	async function saveDrawing(
		drawingId: string,
		versionId: string,
		contextDrawingType: DrawingType
	): Promise<DrawingVersion | null> {
		setSaveStatus(Status.LOADING);
		dispatch(lengthActions.finishLines())
		dispatch(areaActions.closeAreas())
		const data = serializeRootState(store.getState());
		const sourceVersionId = versionId === INITIAL_VERSION_ID ? undefined : versionId;

		try {
			const drawingVersion = await apiInstance.drawingsApi.putDrawingData(drawingId, {...data, sourceVersionId});
			dispatch(viewActions.setEditorIsDirty(false));
			setSaveStatus(Status.SUCCESS);
			NotificationService.successNotification(
				t("common.success"),
				translations(t)["saveDrawingSuccess"][contextDrawingType]
			)
			return drawingVersion
		}
		catch (err: any) {
			setSaveStatus(Status.ERROR);
			LoggerService.logError(err);
			let errorKey: DrawingErrorTypes;
			if (err?.response?.data?.exception?.includes("SourceDrawingVersionIsLockedException")) {
				errorKey = DrawingErrorTypes.VERSION_LOCK
			}
			else if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
				errorKey = DrawingErrorTypes.DRAWING_LOCK
			}
			else {
				errorKey = DrawingErrorTypes.DEFAULT
			}
			NotificationService.errorNotification(
				t("common.error"),
				translations(t)["saveDrawingError"][errorKey][contextDrawingType]
			)
			return null
		}
	}

	return {saveStatus, saveDrawing}
}


export default useDrawingData;
export {useSaveDrawing}
