import React from "react";
import Modal from "../../../../../../components/modal/Modal";
import ModalTitle from "../../../../../../components/modal/ModalTitle";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../components/ui/Button";
import {useTranslation} from "react-i18next";
import "./scaleSetInstructionModal.scss";
import {DrawingType} from "../../../../../../models/enums";

interface ScaleTutorialModalProps {
	open: boolean,
	onClose: () => void,
	drawingType: DrawingType
}

const ScaleSetInstructionModal: React.FC<ScaleTutorialModalProps> = ({open, onClose, drawingType}) => {
	const {t} = useTranslation();

	const translations = {
		"intro": {
			[DrawingType.TAKE_OFF]: t(`editor.scale.setScaleInstructionModal.intro.take_off`),
			[DrawingType.AS_BUILT]: t(`editor.scale.setScaleInstructionModal.intro.as_built`),
			[DrawingType.ASSET_PLAN]: t(`editor.scale.setScaleInstructionModal.intro.asset_plan`)
		},
		"item4": {
			[DrawingType.TAKE_OFF]: t(`editor.scale.setScaleInstructionModal.item4.take_off`),
			[DrawingType.AS_BUILT]: t(`editor.scale.setScaleInstructionModal.item4.as_built`),
			[DrawingType.ASSET_PLAN]: t(`editor.scale.setScaleInstructionModal.item4.asset_plan`)
		}
	}

	return (
		<Modal isOpen={open} onClose={onClose} customClass={"set-scale-modal"}>
			<ModalTitle>{t("editor.scale.setScaleInstructionModal.title")}</ModalTitle>
			{translations["intro"][drawingType]}
			<img src={"/img/set_scale_modal.png"} className={"set-scale-modal_modal-img"} alt={"Scale set modal"}/>
			{translations["intro"][drawingType]}<br/>
			{t("editor.scale.setScaleInstructionModal.listTitle")}
			<ul>
				<li>{t("editor.scale.setScaleInstructionModal.item1")}</li>
				<li>{t("editor.scale.setScaleInstructionModal.item2")}</li>
				<li>{t("editor.scale.setScaleInstructionModal.item3")}</li>
				<li>{translations["item4"][drawingType]}</li>
			</ul>
			<img src={"/img/set_scale_anim.gif"} className={"set-scale-modal_anim"} alt={"Scale set animation"}/>
			<ModalButtons>
				<Button label={t("common.buttons.close")} variant={"optional"} onClick={onClose}/>
			</ModalButtons>
		</Modal>
	);
};

export default ScaleSetInstructionModal
