import {Image, Layer, Rect, Stage} from "react-konva";
import React, {useEffect, useRef} from "react";
import {Provider, ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {usePan} from "./features/pan/usePan";
import Konva from "konva";
import EditorHeader from "./features/header/EditorHeader";
import EditorStageLayers from "./EditorStageLayers";
import "./editor.scss";
import {useDebouncedResizeObserver} from "./hooks/useDebouncedResizeObserver";
import {EditTextContainer} from "./features/text/EditTextContainer";
import {selectEditorGuidelines, selectZoomState, viewActions} from "./features/view/viewSlice";
import {UserSettingsDataContextBridge, useUserSettingsDataContext} from "../../../../providers/UserSettingsProvider";
import {configActions, selectStageConfig} from "./features/config/configSlice";
import {GroupMeasurementSelectGroup} from "./features/group-measurement-select/GroupMeasurementSelectGroup";
import {useZoomOnWheel} from "./features/view/useZoomOnWheel";
import LengthPointContextMenuContainer from "./features/length/LengthPointContextMenuContainer";
import {SymbolCacheDataContextBridge} from "../../../../providers/SymbolCacheDataProvider";
import {useUpdatedRef} from "../../../../hooks/useUpdatedRef";
import {DEFAULT_BLANK_STAGE_HEIGHT, DEFAULT_BLANK_STAGE_WIDTH, MAX_SCALE} from "./constants";
import {getStageBorderPosition} from "./utils";
import useGuideline from "./features/guideline/useGuideline";
import {noop} from "../../../../utils/CallbackUtils";
import {IsAnyModalOpenContextBridge} from "../../../../providers/IsAnyModalOpenProvider";
import {ScaleSetDataContextBridge} from "./features/scale/ScaleSetProvider";
import {EditorPanelDataContextBridge} from "./features/panel/EditorPanelProvider";
import {EditorDrawnItemsDataContextBridge} from "./EditorDrawnItemsDataProvider";
import {useCenterStageOnActiveRecognitionSymbol} from "./features/symbol-recognition";
import {ScaleInfoState} from "../../../base-konva/types";
import {useInitScaleInfoState} from "../../../base-konva/features/config";
import {scaleActions, selectScaleConfig} from "./features/scale/scaleSlice";
import {blurDocumentActiveElement} from "../../../../utils/DOMUtils";
import {DrawingType} from "../../../../models/enums";
import CreateAssetsEditorHeader from "./features/header/CreateAssetsEditorHeader";

interface EditorProps {
	image: HTMLImageElement | undefined;
	isLoading: boolean;
	drawingType: DrawingType;
}

export const Editor: React.FC<EditorProps> = ({image, isLoading, drawingType}) => {
	const dispatch = useDispatch();
	const stageRef = useRef<Konva.Stage>(null)
	const whiteCanvasRef = useRef<Konva.Rect>(null)
	const scrollContainer = useRef<HTMLDivElement>(null)
	const {settings} = useUserSettingsDataContext();
	const zoomState = useSelector(selectZoomState)
	const scaleConfig = useSelector(selectScaleConfig)
	const stageConfig = useSelector(selectStageConfig);
	const guidelines = useSelector(selectEditorGuidelines);
	const zoomStateRef = useUpdatedRef(zoomState)
	const editorHostObserver = useDebouncedResizeObserver()
	const shouldInitZoomScale = useRef(true)
	const {renderGuideline, onMouseMoveGuideline, onMouseLeaveGuideline} = useGuideline();

	const {onMouseDown, onTouchStart} = usePan({stageRef})
	useZoomOnWheel({scrollContainer, stageRef})
	useCenterStageOnActiveRecognitionSymbol({stageRef})

	const setScaleInfoState = function(state: ScaleInfoState) {
		dispatch(scaleActions.setScaleInfoState({value: state}))
	}
	useInitScaleInfoState({scaleConfig, settings, setScaleInfoState, stageConfig, isLoading})

	useEffect(() => {
		if (!isLoading && editorHostObserver.height && editorHostObserver.width) {

			const viewportHeight = editorHostObserver.height;
			const viewportWidth = editorHostObserver.width;
			const stageHeight = stageConfig.height !== 0 ? stageConfig.height : image?.height ?? DEFAULT_BLANK_STAGE_HEIGHT;
			const stageWidth = stageConfig.width !== 0 ? stageConfig.width : image?.width ?? DEFAULT_BLANK_STAGE_WIDTH;

			if (stageConfig.width === 0) {
				dispatch(configActions.setInitialStageConfig({
					value: {
						...stageConfig,
						height: stageHeight,
						width: stageWidth,
					}
				}))
			}
			if (shouldInitZoomScale.current) {
				shouldInitZoomScale.current = false;

				const {current: zoomState} = zoomStateRef
				let newScale: number

				if (zoomState.savedScale === undefined) {
					const viewportFillScale = Math.min(
						viewportWidth / stageWidth,
						viewportHeight / stageHeight
					)
					newScale = viewportFillScale > 1 ? 1 : viewportFillScale;
				}
				else {
					newScale = zoomState.savedScale
				}

				const validateScale = () => {
					if (newScale < zoomState.minScale) return zoomState.minScale
					if (newScale > MAX_SCALE) return MAX_SCALE
					return newScale
				}

				newScale = validateScale()

				const stage = stageRef.current
				if (stage) {
					const x = viewportWidth / 2
					stage.position({
						x: x,
						y: (stageHeight / 2) * newScale
					})
					stage.batchDraw()
				}

				dispatch(viewActions.setZoomState({
					...zoomState,
					scale: newScale
				}))
			}

			dispatch(viewActions.setDimensions({
				viewportHeight, viewportWidth, stageHeight, stageWidth
			}))
		}
	}, [image, isLoading, dispatch, editorHostObserver.height, editorHostObserver.width, stageConfig, zoomStateRef])

	const cls = [
		"editor_host_scroll-container",
	].filter(Boolean).join(" ")

	useEffect(() => {
		const stage = stageRef.current
		if (stage) {
			const position = stage.position()
			stage.position(getStageBorderPosition(
				position,
				stage.getClientRect(),
				zoomStateRef.current.viewportSize
			))
			stage.batchDraw()
		}
	}, [zoomState.rotation, zoomStateRef]);

	return (
		<div className={"editor"}>
			{drawingType === DrawingType.ASSET_PLAN ? <CreateAssetsEditorHeader/> : <EditorHeader/>}
			<div ref={editorHostObserver.ref} className={"editor_host"}>
				<div ref={scrollContainer}
					 className={cls}
					 style={{
						 height: zoomState.viewportSize.height,
						 width: zoomState.viewportSize.width,
					 }}>
					<EditorDrawnItemsDataContextBridge.Consumer>{editorDrawnItemsData => (
						<EditorPanelDataContextBridge.Consumer>{editorPanelData => (
							<IsAnyModalOpenContextBridge.Consumer>{modalData => (
								<SymbolCacheDataContextBridge.Consumer>{symbolCache => (
									<UserSettingsDataContextBridge.Consumer>{settings => (
										<ReactReduxContext.Consumer>{({store}) => (
											<ScaleSetDataContextBridge.Consumer>{scaleData => (
												<Stage
													ref={stageRef}
													listening={true}
													rotation={zoomState.rotation}
													offsetX={stageConfig.width / 2}
													offsetY={stageConfig.height / 2}
													height={zoomState.viewportSize.height}
													width={zoomState.viewportSize.width}
													scale={{x: zoomState.scale, y: zoomState.scale}}
													onMouseDown={onMouseDown}
													onTouchStart={evt => {
														onTouchStart(evt)
														blurDocumentActiveElement()
													}}
												>
													<SymbolCacheDataContextBridge.Provider value={symbolCache}>
														<UserSettingsDataContextBridge.Provider value={settings}>
															<ScaleSetDataContextBridge.Provider value={scaleData}>
																<Provider store={store}>
																	<IsAnyModalOpenContextBridge.Provider value={modalData}>
																		<EditorPanelDataContextBridge.Provider
																			value={editorPanelData}>
																			<EditorDrawnItemsDataContextBridge.Provider
																				value={editorDrawnItemsData}>
																				<Layer listening={true}
																					   onMouseMove={guidelines ?
																						   onMouseMoveGuideline : noop}
																					   onDragMove={guidelines ?
																						   onMouseMoveGuideline :
																						   noop}
																					   onMouseLeave={guidelines ?
																						   onMouseLeaveGuideline : noop}>
																					<Rect fill={"white"}
																						  ref={whiteCanvasRef}
																						  x={0} y={0}
																						  width={stageConfig.width}
																						  height={stageConfig.height}/>
																					<Image image={image}
																						   {...stageConfig.backgroundImagePosition}/>
																					<EditorStageLayers/>
																					<GroupMeasurementSelectGroup/>
																					{guidelines ? renderGuideline() :
																						null}
																				</Layer>
																			</EditorDrawnItemsDataContextBridge.Provider>
																		</EditorPanelDataContextBridge.Provider>
																	</IsAnyModalOpenContextBridge.Provider>
																</Provider>
															</ScaleSetDataContextBridge.Provider>
														</UserSettingsDataContextBridge.Provider>
													</SymbolCacheDataContextBridge.Provider>
												</Stage>
											)}
											</ScaleSetDataContextBridge.Consumer>
										)}
										</ReactReduxContext.Consumer>
									)}
									</UserSettingsDataContextBridge.Consumer>
								)}
								</SymbolCacheDataContextBridge.Consumer>
							)}
							</IsAnyModalOpenContextBridge.Consumer>
						)}
						</EditorPanelDataContextBridge.Consumer>
					)}
					</EditorDrawnItemsDataContextBridge.Consumer>
					<EditTextContainer whiteCanvasRef={whiteCanvasRef}/>
				</div>
			</div>
			<LengthPointContextMenuContainer/>
		</div>
	)
}
