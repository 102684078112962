import {PinnedMeasurementApiDto} from "./restModels";
import {MaterialType, MeasurementType} from "../features/project-drawings/features/editor/models/enums";
import {
	AreaMeasurement,
	AreaStyle,
	CountMeasurement,
	CountMeasurementStyle,
	LengthMeasurement,
} from "../features/project-drawings/features/editor/models/editor";
import LoggerService from "../services/LoggerService";
import isEqual from "lodash/isEqual";
import {LineStyle} from "../features/base-konva/types";

export class PinnedMeasurement {
	constructor(public readonly id: string,
		public readonly labourTime: number | undefined,
		public readonly materialId: number | undefined,
		public readonly materialName: string,
		public readonly materialPartNumber: string | undefined,
		public readonly materialType: MaterialType,
		public readonly measurementType: MeasurementType,
		public readonly height: number | undefined,
		public readonly defaultDrop: number | undefined,
		public readonly style: CountMeasurementStyle | LineStyle | AreaStyle,
		public readonly isAsset: boolean | undefined,
		public readonly  assetTypeId: number | undefined) {}

	public static fromJson(json: PinnedMeasurementApiDto): PinnedMeasurement {
		const materialType = MaterialType[json.materialType];
		if (materialType === undefined) {
			const error = new Error(`Unknown material type: ${json.materialType}`);
			LoggerService.logError(error);
			throw error;
		}
		const measurementType = MeasurementType[json.measurementType];
		if (measurementType === undefined) {
			const error = new Error(`Unknown measurement type: ${json.measurementType}`);
			LoggerService.logError(error);
			throw error;
		}

		const style = JSON.parse(decodeURIComponent(json.style)) as CountMeasurementStyle | LineStyle | AreaStyle;

		// TODO: api should be fixed to not return null values when undefined (to avoid "?? undefined" casting)
		return new PinnedMeasurement(json.id, json.labourTime, json.materialId ?? undefined, json.materialName,
			json.materialPartNumber ?? undefined, materialType, measurementType, json.height ?? undefined,
			json.defaultDrop ?? undefined, style, json.isAsset, json.assetTypeId);
	}

	getStyleAsString() {
		return encodeURIComponent(JSON.stringify(this.style));
	}

	equalsMeasurement(measurement: CountMeasurement | LengthMeasurement | AreaMeasurement) {
		return measurement.material.id === this.materialId
			&& measurement.material.name === this.materialName
			&& measurement.material.type === this.materialType
			&& measurement.material.partNumber === this.materialPartNumber
			&& measurement.type === this.measurementType
			&& isEqual(measurement.style, this.style);
	}
}
