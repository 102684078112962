import React from "react";
import {selectCountGroups} from "../count/countSlice";
import {useSelector} from "react-redux";
import EditorPanelTabContent from "./EditorPanelTabContent";
import CountPanelItem from "./count/CountPanelItem";
import {isAssetPlanProps} from "../../constants";

const CountPanelTab: React.FC<isAssetPlanProps> = ({isAssetPlan}) => {
	const countGroups = useSelector(selectCountGroups);

	return (
		<EditorPanelTabContent>
			{countGroups.map(group => <CountPanelItem key={group.id} countGroup={group} isAssetPlan={isAssetPlan}/>)}
		</EditorPanelTabContent>
	);
};

export default CountPanelTab
