import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../../../components/modal/EditValueModal";
import {DrawingType, Status} from "../../../../../models/enums";
import "./modalContent.scss";
import DrawingNameInput from "../../../../project-drawings/features/editor/components/DrawingNameInput";
import useRenameDrawing from "../../../hooks/useRenameDrawing";
import NotificationService from "../../../../../services/NotificationService";

interface ChangeDrawingNameModalProps {
	isOpen: boolean,
	drawingId: string,
	drawingName: string,
	drawingType: DrawingType,
	onSuccess: () => void,
	onClose: () => void,
	existingDrawingNames: string[];
}

const ChangeDrawingNameModal: React.FC<ChangeDrawingNameModalProps> = ({
	isOpen,
	drawingId,
	drawingName,
	drawingType,
	onSuccess,
	onClose,
	existingDrawingNames
}) => {

	const [name, setName] = useState<string | undefined>(drawingName);
	const {t} = useTranslation();

	const {saveStatus, renameDrawing} = useRenameDrawing(drawingId, drawingType, handleOnSuccess);

	function handleOnSuccess() {
		onSuccess();
	}

	const handleSubmit = () => {
		if (name && name !== drawingName && existingDrawingNames.includes(name)) {
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.renameDrawingModal.renameErrorDesc.name_exists")
			);
		}
		else {
			name && renameDrawing(name);
		}
	};

	const clearData = () => {
		setName(undefined);
	};

	const handleClose = () => {
		clearData();
		onClose();
	};

	const modalTitleTranslations = {
		[DrawingType.TAKE_OFF]: t(`projects.details.renameDrawingModal.title.take_off`),
		[DrawingType.AS_BUILT]: t(`projects.details.renameDrawingModal.title.as_built`),
		[DrawingType.ASSET_PLAN]: t(`projects.details.renameDrawingModal.title.asset_plan`)
	}

	return (
		<EditValueModal
			isOpen={isOpen}
			onClose={handleClose}
			onSave={handleSubmit}
			title={modalTitleTranslations[drawingType]}
			disableSave={!name || saveStatus === Status.LOADING}
			isLoading={saveStatus === Status.LOADING}
			saveLabel={t("common.buttons.done")}
		>
			<div className="modal-content">
				<div className="modal-content_field">
					<div className="modal-content_field_label">
						{t("projects.details.renameDrawingModal.inputLabel")}
					</div>
					<DrawingNameInput
						value={name}
						onChange={setName}
						placeholder={t("projects.details.renameDrawingModal.inputPlaceholder")}
					/>
				</div>
			</div>
		</EditValueModal>
	);
};

export default ChangeDrawingNameModal;
