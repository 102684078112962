import {AxiosInstance} from "axios";
import {PinnedMeasurement} from "../models/PinnedMeasurement";
import {MaterialType, MeasurementType} from "../features/project-drawings/features/editor/models/enums";

export default class PinnedMeasurementsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchPinnedMeasurements(): Promise<PinnedMeasurement[]> {
		const url = "/api/pinned_materials";
		return this.axiosInstance.get(url).then(resp => resp.data.map(PinnedMeasurement.fromJson));
	}

	postPinnedMeasurement(materialId: number | undefined,
		materialType: MaterialType,
		measurementType: MeasurementType,
		name: string | undefined,
		height: number | undefined,
		defaultDrop: number | undefined,
		style: string,
		isAsset: boolean | undefined,
		assetTypeId: number | undefined,): Promise<void> {
		const url = "/api/pinned_materials";
		const data = {
			materialId,
			materialType,
			measurementType,
			name,
			height,
			defaultDrop,
			style,
			isAsset,
			assetTypeId
		};
		return this.axiosInstance.post(url, data);
	}

	putPinnedMeasurement(pinnedMeasurementId: string,
		materialId: number | undefined,
		materialType: MaterialType,
		measurementType: MeasurementType,
		name: string | undefined,
		height: number | undefined,
		defaultDrop: number | undefined,
		style: string,
		isAsset: boolean | undefined,
		assetTypeId: number | undefined): Promise<void> {
		const url = `/api/pinned_materials/${pinnedMeasurementId}/edit`;
		const data = {
			materialId,
			materialType,
			measurementType,
			name,
			height,
			defaultDrop,
			style,
			isAsset,
			assetTypeId
		};
		return this.axiosInstance.put(url, data);
	}

	deletePinnedMeasurement(pinnedMeasurementId: string): Promise<void> {
		const url = `/api/pinned_materials/${pinnedMeasurementId}`;
		return this.axiosInstance.delete(url);
	}
}
