import React, {useState} from 'react'
import "./BulkAssetAssignment.scss";
import {
    Paper,
    Popper,
    Tabs,
    Tab
} from "@mui/material";
import IconMaterialAdd from "../../../../../components/icons/IconMaterialAdd";
import IconMaterial from "../../../../../components/icons/IconMaterial";
import {RemoteSymbol} from "../models/editor";
import SymbolPreviewElement from "../../../../symbols/components/SymbolPreviewElement";
import {useTranslation} from "react-i18next";
import {SymbolType} from "../icons/symbol_pack/default";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import AssetCustomFieldsComponent from "./AssetCustomFields";
import useAssetCustomFields from "../../../../../hooks/useAssetCustomFields";
import {Status} from "../../../../../models/enums";

interface IndividualAssetAssignmentProps {
    symbol?: SymbolType
    color?: string
    remoteSymbol?: RemoteSymbol
    id: String;
    materialName: String;
    assetTypeId?: number;
}

const IndividualAssetAssignment: React.FC<IndividualAssetAssignmentProps> = ({id, materialName , assetTypeId, symbol, color, remoteSymbol}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [arrowRef] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const {loadStatus} = useAssetCustomFields(assetTypeId)
    const [activeTab, setActiveTab] = useState(0);
    const [itemsSaved, setItemsSaved] = useState(false);


    function toggle() {
        setOpen(!open)
    }

    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    const handleSave = () => {
        setItemsSaved(true);
        close()
    }

    return (
        <>
            {!itemsSaved ? <div ref={setAnchorEl} onClick={toggle}>
                <IconMaterialAdd />
            </div> : <IconMaterial />}
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={'right-start'}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                <ClickAwayListener onClickAway={close}>
                    <Paper className='bulk-assignment individual'>
                        <div className='bulk-assignment_title'>
                            <div className='bulk-assignment_symbol'>
                                {color ? <SymbolPreviewElement color={color}
                                                               symbol={symbol}
                                                               remoteSymbol={remoteSymbol}/> : null}
                            </div>
                            <div className='bulk-assignment_name'>
                                {t("common.assetCreation.assetCreationText")}
                            </div>
                        </div>
                        <div className="bulk-assignment_content_label">
                            {materialName}
                        </div>
                        <div className='bulk-assignment_arrow individual'></div>
                        <Tabs
                            value={activeTab}
                            onChange={(_, newValue) => setActiveTab(newValue)}
                            indicatorColor="primary"
                            textColor="inherit"
                            className={`bulk-assignment_tabs`}
                            variant={"fullWidth"}
                            sx={{
                                "& .MuiTabs-indicator": {
                                    display: "none",
                                },
                            }}
                        >
                            <Tab
                                label={t('common.assetCreation.createNew')}
                                className={activeTab === 0 ? "tabs_active" : "tabs_inactive"}

                            />
                            <Tab
                                label={t('common.assetCreation.mapToExisting')}
                                className={activeTab === 1 ? "tabs_active" : "tabs_inactive"}
                            />
                        </Tabs>
                        <div className="bulk-assignment_content">
                            {activeTab === 0 ? (
                                <AssetCustomFieldsComponent assetTypeId={assetTypeId}/>
                            ) : (
                                <div>
                                    <p>Content for Tab 2</p>
                                </div>
                            )}
                        </div>
                        <div className='bulk-assignment_close'>
                            <>
                                {loadStatus !== Status.NO_DATA && (
                                    <div className='asset-custom-fields_button asset-custom-fields_save'
                                         onClick={handleSave}>
                                        {t('common.buttons.save')}
                                    </div>
                                )}
                                <div className='asset-custom-fields_button asset-custom-fields_cancel'
                                     onClick={close}>
                                    {t('common.buttons.cancel')}
                                </div>
                            </>

                        </div>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
};

export default IndividualAssetAssignment;
