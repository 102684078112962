import React from "react";
import {selectPenGroups} from "../pen/penSlice";
import {useSelector} from "react-redux";
import EditorPanelTabContent from "./EditorPanelTabContent";
import PenPanelItem from "./layers/PenPanelItem";
import {selectTextGroups} from "../text/textSlice";
import TextPanelItem from "./layers/TextPanelItem";
import {selectImages} from "../image/imageSlice";
import {ImagePanelItem} from "./layers/ImagePanelItem";
import {isAssetPlanProps} from "../../constants";

const LayersPanelTab: React.FC<isAssetPlanProps> = ({isAssetPlan}) => {

	const penGroups = useSelector(selectPenGroups);
	const textGroups = useSelector(selectTextGroups);
	const images = useSelector(selectImages);

	return (
		<EditorPanelTabContent>
			{penGroups.map(group => <PenPanelItem key={group.id} penGroup={group} isAssetPlan={isAssetPlan}/>)}
			{textGroups.map(group => <TextPanelItem key={group.id} textGroup={group} isAssetPlan={isAssetPlan}/>)}
			{images.map(image => <ImagePanelItem key={image.id} image={image} isAssetPlan={isAssetPlan}/>)}
		</EditorPanelTabContent>
	);
};

export default LayersPanelTab
