import React from 'react';
import "./editorPanel.scss";
import {PinnedItemsEditorPanel} from "./PinnedItemsEditorPanel";
import {TakeoffsEditorPanel} from "./TakeoffsEditorPanel";
import {EditorPanelDataContextBridge} from './EditorPanelProvider';
import {EditorPanelMode} from "../../models/enums";
import {MaterialModalProvider} from "./material/MaterialModalProvider";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import {DrawingType} from "../../../../../../models/enums";

export const EditorPanel = () => {
	const {drawingData: {drawingDetails}} = useDrawingDetailsDataContext()
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	return (
		<MaterialModalProvider>
			<div className="editor-panel_container">
				<EditorPanelDataContextBridge.Consumer>
					{data => data
						? (data.editorPanelMode === EditorPanelMode.TAKEOFF_ITEMS || drawingType === DrawingType.ASSET_PLAN
							? <TakeoffsEditorPanel isAssetPlan={drawingType === DrawingType.ASSET_PLAN}/> : <PinnedItemsEditorPanel/>)
						: null}
				</EditorPanelDataContextBridge.Consumer>
			</div>
		</MaterialModalProvider>
	);
};
