import React, {useState} from "react";
import {lengthActions, selectActiveLengthId} from "../../length/lengthSlice";
import {useDispatch, useSelector} from "react-redux";
import {configActions, selectActiveTool, selectEditorLockState} from "../../config/configSlice";
import "./lengthPanelItem.scss";
import {LengthMeasurement} from "../../../models/editor";
import IconArrowSmall from "../../../icons/IconArrowSmall";
import Dropdown from "../../../../../../../components/ui/Dropdown";
import DropdownItem from "../../../../../../../components/ui/DropdownItem";
import {useTranslation} from "react-i18next";
import WastageItem from "./WastageItem";
import MeasurementStyleSelectorModal from "../../../components/MeasurementStyleSelectorModal";
import ConfirmationModal from "../../../../../../../components/ui/ConfirmationModal";
import DropItem from "./DropItem";
import LengthHelper from "../../length/LengthHelper";
import {formatLength} from "../../../../../../../utils/TextUtils";
import {useHighlightItem, useHighlightSetter} from "../../../hooks/highlight";
import {useUserSettingsDataContext} from "../../../../../../../providers/UserSettingsProvider";
import {LengthPanelItemModal} from "../../../../../../../models/enums";
import {EditorTool, MaterialType, MeasurementType} from "../../../models/enums";
import {isLengthFinished} from "../../../utils";
import SetNumberValueModal from "./SetNumberValueModal";
import {useEditorPanelDataContext} from "../EditorPanelProvider";
import {IconPin} from "../../../icons/side_panel/IconPin";
import {useMaterialModalContext} from "../material/MaterialModalProvider";
import IconVisibleButton from "../../../components/IconVisibleButton";
import {LineStyle} from "../../../../../../base-konva/types";
import {getId} from "../../../../../../../utils";
import {selectScale} from "../../scale/scaleSlice";
import BulkAssetAssignment from "../../../components/BulkAssetAssignment";

type LengthPanelItemProps = {
	length: LengthMeasurement;
	isAssetPlan: boolean
}

const LengthPanelItem: React.FC<LengthPanelItemProps> = ({length, isAssetPlan}) => {
	const dispatch = useDispatch();
	const scale = useSelector(selectScale);
	const editorLocked = useSelector(selectEditorLockState);
	const activeTool = useSelector(selectActiveTool);
	const lengthActive = useSelector(selectActiveLengthId) === length.id && activeTool === EditorTool.LENGTH;
	const {settings} = useUserSettingsDataContext();
	const {openMaterialChangeModal, openOneOffChangeModal} = useMaterialModalContext()
	const {pinnedItems, findPinnedMeasurement} = useEditorPanelDataContext()
	const {t} = useTranslation();

	const [modal, setModal] = useState<LengthPanelItemModal>(LengthPanelItemModal.NONE);
	const [isExpanded, setIsExpanded] = useState(false);
	const highlightActions = useHighlightSetter(length.id);
	const {isItemHover} = useHighlightItem(length.id);
	const isOneOff = length.material.type === MaterialType.ONE_OFF;

	const openWastageModal = () => setModal(LengthPanelItemModal.WASTAGE);
	const openDeleteModal = () => setModal(LengthPanelItemModal.DELETE);
	const openStyleModal = () => setModal(LengthPanelItemModal.STYLE);
	const openChangeMaterialModal = () => openMaterialChangeModal({
		measurementId: length.id,
		measurementStyle: length.style,
		measurementType: EditorTool.LENGTH
	});
	const openChangeOneOffModal = (modalTitle: string) => () => openOneOffChangeModal({
		measurementId: length.id,
		measurementStyle: length.style,
		measurementType: EditorTool.LENGTH,
		materialName: length.material.name,
		modalTitle: modalTitle
	})
	const openChangeDropModal = () => setModal(LengthPanelItemModal.DROP);
	const closeModal = () => setModal(LengthPanelItemModal.NONE);

	function toggleExpand() {
		setIsExpanded(!isExpanded);
	}

	const totalDistance = LengthHelper.getTotalLength(length, scale)

	const cls = [
		"panel-item",
		lengthActive && "is-active",
		isExpanded && "is-expanded",
	].filter(Boolean).join(" ");

	const panelItemBarCls = [
		"panel-item_bar",
		isItemHover && "is-hover",
	].filter(Boolean).join(" ");

	function handleActivate() {
		const activationId = getId();
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.LENGTH, actionId: activationId}));
		dispatch(lengthActions.activateLength({id: length.id, actionId: activationId}))
		dispatch(lengthActions.setVisibility({id: length.id, visible: true, actionId: activationId}))
	}

	function handleDeactivate() {
		dispatch(lengthActions.clearActiveLength({}));
	}

	function toggleActivate() {
		lengthActive ? handleDeactivate() : handleActivate()
	}

	function toggleVisibility() {
		dispatch(lengthActions.setVisibility({id: length.id, visible: !length.visible}))
	}

	function handleStyleSave(style: LineStyle) {
		closeModal();
		dispatch(lengthActions.changeLengthStyle({id: length.id, style}));
	}

	function handleDelete() {
		dispatch(lengthActions.deleteLength({id: length.id}));
	}

	function handleUnassignAsset() {
		// TODO
	}

	function handleSetWastage(value: number) {
		dispatch(lengthActions.setWastage({id: length.id, value}));
		closeModal();
		setIsExpanded(true);
	}

	function handleDeleteWastage() {
		dispatch(lengthActions.setWastage({id: length.id, value: undefined}));
	}

	function handleDeleteDrop(id: string) {
		dispatch(lengthActions.deleteDrop({parentId: length.id, id}))
	}

	function handleChangeDropValue(id: string, value: number) {
		dispatch(lengthActions.setDropValue({parentId: length.id, id, value}))
	}

	function handleChangeDropModal(value: number) {
		if (isLengthFinished(length)) {
			dispatch(lengthActions.setAllDropsValue({id: length.id, value}))
		}
		else {
			dispatch(lengthActions.setDefaultDropValue({id: length.id, value}))
		}
		closeModal()
	}

	const pinnedMeasurement = findPinnedMeasurement(length);

	function addToPinnedItems() {
		pinnedItems.createPinnedMeasurement(
			length.material.id,
			length.material.type,
			MeasurementType.LENGTH,
			length.material.name,
			undefined,
			length.defaultDrop,
			length.style,
			!!(length.material && length.material.isAsset),
			length.material && length.material.assetTypeId ? length.material.assetTypeId : 0
		);
	}

	function removeFromPinnedItems() {
		pinnedItems.deletePinnedMeasurement(pinnedMeasurement!.id)
	}

	return (
		<>
			<SetNumberValueModal isOpen={modal === LengthPanelItemModal.WASTAGE}
								 initialValue={length.wastage}
								 onSave={handleSetWastage}
								 onClose={closeModal}
								 title={t("editor.length.setWastageModal.title")}
								 inputLabel={t("editor.length.setWastageModal.inputLabel")}/>
			<MeasurementStyleSelectorModal isOpen={modal === LengthPanelItemModal.STYLE}
										   initialStyle={length.style}
										   measurementType={EditorTool.LENGTH}
										   onSave={(style) => handleStyleSave(style as LineStyle)}
										   onClose={closeModal}/>
			<ConfirmationModal isOpen={modal === LengthPanelItemModal.DELETE}
							   text={t("editor.deleteItemConfirmationModalQuestion")}
							   onConfirm={handleDelete}
							   onClose={closeModal}/>
			<SetNumberValueModal isOpen={modal === LengthPanelItemModal.DROP}
								 initialValue={length.defaultDrop}
								 title={isLengthFinished(length)
									 ? t("editor.length.setAllDropsValueModal.title")
									 : t("editor.length.setDefaultDropValueModal.title")}
								 inputLabel={isLengthFinished(length)
									 ? t("editor.length.setAllDropsValueModal.inputLabel")
									 : t("editor.length.setDefaultDropValueModal.inputLabel")}
								 onSave={handleChangeDropModal}
								 onClose={closeModal}/>
			<div className={cls}>
				<div className={panelItemBarCls}
					 onMouseEnter={highlightActions.setHighlight}
					 onMouseLeave={highlightActions.clearHighlight}>
					<div className={"panel-item_bar_icon"} onClick={toggleExpand}>
						<IconArrowSmall isActive={lengthActive}/>
					</div>
					<div className="panel-item_bar_content" onClick={!isAssetPlan ? toggleActivate : undefined}>
						<div className="panel-item_bar_content_label">
							{length.material.name}
						</div>
						{!!pinnedMeasurement && (
							<div className="panel-item_bar_content_pin-icon"><IconPin/></div>
						)}
						<div className="panel-item_bar_content_spacer"/>
					</div>
					<div className={"panel-item_bar_props"}>({formatLength(totalDistance, settings?.measurementSystem)})</div>
					{!isAssetPlan &&
					<div className={"panel-item_bar_actions"}>
						<IconVisibleButton onClick={toggleVisibility} visible={length.visible} isActive={lengthActive}/>
						<Dropdown disabled={editorLocked} isActive={lengthActive}>
							{isOneOff &&
								<>
									<DropdownItem label={t("common.buttons.edit")}
												  onClick={openChangeOneOffModal(t("projects.oneOffModal.title.editItem"))}/>
									<DropdownItem label={t("editor.convertToCatalogue")} onClick={openChangeMaterialModal}/>
								</>}
							{!isOneOff &&
								<>
									<DropdownItem label={t("common.buttons.edit")} onClick={openStyleModal}/>
									<DropdownItem label={t("editor.changeMaterial")} onClick={openChangeMaterialModal}/>
									<DropdownItem label={t("editor.convertToOneOff")}
												  onClick={openChangeOneOffModal(t("editor.convertToOneOff"))}/>
								</>}
							<DropdownItem label={t("common.buttons.delete")} onClick={openDeleteModal}/>
							<DropdownItem label={t("editor.length.setWastage")}
										  onClick={openWastageModal}/>
							{isLengthFinished(length) ? (
								<DropdownItem label={t("editor.length.setAllDropsValueModal.inputLabel")}
											  onClick={openChangeDropModal}/>
							) : (
								<DropdownItem label={t("editor.length.setDefaultDropValueModal.inputLabel")}
											  onClick={openChangeDropModal}/>
							)}
							{!!pinnedMeasurement ? (
								<DropdownItem label={t("editor.panel.pinnedItems.removeFromPinnedItems")}
											  onClick={removeFromPinnedItems}/>
							) : (
								<DropdownItem label={t("editor.panel.pinnedItems.addToPinnedItems")}
											  onClick={addToPinnedItems}/>
							)}
						</Dropdown>
					</div>
					}
					{
						isAssetPlan &&
						<div className={"panel-item_bar_actions"}>
							<BulkAssetAssignment color={undefined}
												 symbol={undefined}
												 remoteSymbol={undefined}
												 id={length.id}
												 materialName={length.material.name}
												 assetTypeId={length.material.assetTypeId}
												 appendix={formatLength(totalDistance,
													 settings?.measurementSystem)}></BulkAssetAssignment>
							<Dropdown disabled={editorLocked} isActive={lengthActive}>
								<DropdownItem label={t("common.buttons.delete")} onClick={handleUnassignAsset}/>
							</Dropdown>
						</div>
					}
				</div>
				<div className={"panel-item_content"}>
					{length.wastage ? <WastageItem value={length.wastage}
												   disabled={editorLocked}
												   onDelete={handleDeleteWastage}
												   onPropsClick={openWastageModal}
												   isAssetPlan={isAssetPlan}/> : null}
					{length.drops.map(drop =>
						<DropItem key={drop.id} drop={drop}
								  disabled={editorLocked}
								  onDelete={handleDeleteDrop}
								  onChange={handleChangeDropValue}
								  isAssetPlan={isAssetPlan}/>)}
				</div>
			</div>
		</>
	);
};

export default LengthPanelItem;
