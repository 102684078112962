import {DrawingType, Status} from "../models/enums";
import {OnSuccess} from "../utils/CallbackUtils";
import {apiInstance} from "../api/api";
import NotificationService from "../services/NotificationService";
import LoggerService from "../services/LoggerService";
import {useState} from "react";
import {useTranslation} from "react-i18next";

interface UseDeleteDrawingHook {
	deleteStatus: Status,
	deleteDrawing: () => void,
}

enum DeleteDrawingsErrorTypes {
	LOCK = "LOCK",
	DEFAULT = "DEFAULT"
}

function useDeleteDrawing(drawingId: string, drawingType: DrawingType, onSuccess: OnSuccess): UseDeleteDrawingHook {

	const {t} = useTranslation();
	const [deleteStatus, setDeleteStatus] = useState(Status.IDLE)

	const translations = {
		"deleteDrawingSuccess": {
			[DrawingType.TAKE_OFF]: t(`projects.details.tree.deleteDrawingSuccess.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.tree.deleteDrawingSuccess.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.tree.deleteDrawingSuccess.asset_plan`),
		},
		"deleteDrawingError": {
			[DeleteDrawingsErrorTypes.LOCK]: {
				[DrawingType.TAKE_OFF]: t(`projects.details.tree.deleteDrawingErrorDescLock.take_off`),
				[DrawingType.AS_BUILT]: t(`projects.details.tree.deleteDrawingErrorDescLock.as_built`),
				[DrawingType.ASSET_PLAN]: t(`projects.details.tree.deleteDrawingErrorDescLock.asset_plan`)
			},
			[DeleteDrawingsErrorTypes.DEFAULT]: {
				[DrawingType.TAKE_OFF]: t(`projects.details.tree.deleteDrawingErrorDesc.take_off`),
				[DrawingType.AS_BUILT]: t(`projects.details.tree.deleteDrawingErrorDesc.as_built`),
				[DrawingType.ASSET_PLAN]: t(`projects.details.tree.deleteDrawingErrorDesc.asset_plan`)
			}
		}
	}

	function deleteDrawing() {
		setDeleteStatus(Status.LOADING);
		apiInstance.drawingsApi.deleteDrawing(drawingId)
			.then(() => {
				onSuccess();
				setDeleteStatus(Status.SUCCESS)
				NotificationService.successNotification(t("common.success"),
					translations["deleteDrawingSuccess"][drawingType]);
			})
			.catch(err => {
				setDeleteStatus(Status.ERROR)
				let errorKey: DeleteDrawingsErrorTypes;
				if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
					errorKey = DeleteDrawingsErrorTypes.LOCK
				}
				else {
					errorKey = DeleteDrawingsErrorTypes.DEFAULT
				}
				NotificationService.errorNotification(t("common.error"),
					translations["deleteDrawingError"][errorKey][drawingType]);
				LoggerService.logError(err);
			})
	}

	return {deleteStatus, deleteDrawing}
}

export default useDeleteDrawing