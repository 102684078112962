import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../../../components/modal/EditValueModal";
import "./createDrawingModal.scss";
import {useLocation, useParams} from "react-router-dom";
import {DrawingType, Status} from "../../../../../models/enums";
import {useCreateCostCenter, useCreateDrawings} from "../../../hooks";
import DrawingNameInput from "../../../../project-drawings/features/editor/components/DrawingNameInput";
import DrawingDetails from "../../../../../models/DrawingDetails";
import NotificationService from "../../../../../services/NotificationService";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {apiInstance} from "../../../../../api/api";
import CreateCostCenterInputField from "./CreateCostCenterInputField";
import CreateSectionInputField from "./CreateSectionInputField";
import {INITIAL_VERSION_ID} from "../../../../project-drawings/features/editor/constants";
import LoggerService from "../../../../../services/LoggerService";
import {PlanUploadData, PlanUploadPageData} from "../../../features/plan-upload/types";
import {pages, useNavigator} from "../../../../navigator";

interface CreateDrawingModalProps {
	planPage?: PlanUploadPageData,
	planTemplate?: PlanUploadData,
	drawingType: DrawingType
	onSuccess: () => void,
	onClose: () => void,
	initialData?: { costCenterId?: string, sectionId?: string }
}

export enum CreateDrawingModalFieldMode {
	EXISTING = "EXISTING",
	CREATE_NEW = "CREATE_NEW"
}

const CreateDrawingModal: React.FC<CreateDrawingModalProps> = ({
	planPage,
	planTemplate,
	drawingType,
	onSuccess,
	onClose,
	initialData
}) => {
	const {isServiceProject, treeData: {sections}} = useProjectDetailContext()
	const {createCostCenter} = useCreateCostCenter();
	const [name, setName] = useState<string>("");
	const [sectionId, setSectionId] = useState<string | undefined>(initialData?.sectionId);
	const [costCenterName, setCostCenterName] = useState<string>("")
	const [companyCostCenterId, setCompanyCostCenterId] = useState<number | undefined>(undefined)
	const [costCenterId, setCostCenterId] = useState<string | undefined>(initialData?.costCenterId);
	const [createSectionFieldMode, setCreateSectionFieldMode] = useState<CreateDrawingModalFieldMode>(
		CreateDrawingModalFieldMode.EXISTING)
	const [sectionName, setSectionName] = useState<string>("")
	const [createCostCenterFieldMode, setCreateCostCenterFieldMode] = useState<CreateDrawingModalFieldMode>(
		CreateDrawingModalFieldMode.EXISTING)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const {projectId} = useParams<{ projectId: string }>();
	const {
		saveStatus, createDrawing, createBlankDrawing
	} = useCreateDrawings(planTemplate, planPage?.pageNumber);
	const {t} = useTranslation();
	const {navigateTo} = useNavigator()
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const isSectionFieldInExistingMode = createSectionFieldMode === CreateDrawingModalFieldMode.EXISTING
	const isSectionFieldInCreateMode = createSectionFieldMode === CreateDrawingModalFieldMode.CREATE_NEW
	const isCostCenterFieldInExistingMode = createCostCenterFieldMode === CreateDrawingModalFieldMode.EXISTING
	const isCostCenterFieldInCreateMode = createCostCenterFieldMode === CreateDrawingModalFieldMode.CREATE_NEW
	const existingSectionProvided = isSectionFieldInExistingMode && (isServiceProject || sectionId);
	const sectionCreationFieldFilled = isSectionFieldInCreateMode && sectionName.length > 0

	const existingCostCenterProvided = isCostCenterFieldInExistingMode && costCenterId;
	const costCenterCreationFieldsFilled = isCostCenterFieldInCreateMode && companyCostCenterId;
	const saveDisabled: boolean = !(
		name
		&& (existingSectionProvided || sectionCreationFieldFilled)
		&& (existingCostCenterProvided || costCenterCreationFieldsFilled));

	const handleSectionChange = (sectionId: string) => {
		setSectionId(sectionId);
		setCostCenterId(undefined);
	};

	const sectionsToSelect = useMemo(() => {
		return sections.filter(section => !section.deletedExternal)
	}, [sections])

	const costCentersToSelect = useMemo(() => {
		return sectionsToSelect
			.find(s => s.id === sectionId)?.costCenters
			.filter(cc => !cc.deletedExternal)
			.map(cc => ({label: cc.name, value: cc.id})) || [];
	}, [sectionsToSelect, sectionId])

	const clearData = () => {
		setName("");
		setCostCenterId(undefined);
		!isServiceProject && setSectionId(undefined);
	};

	useEffect(() => {
		setSectionId(isServiceProject ? sections[0]?.id : initialData?.sectionId)
	}, [sections, isServiceProject, initialData?.sectionId])

	const handleClose = () => {
		clearData();
		onClose();
	}

	const onCreateDrawingsSuccess = () => {
		clearData();
		onSuccess();
	};

	const findSelectedCostCenter = () => {
		return sections
			.find(section => section.id === sectionId)?.costCenters
			.find(cc => cc.id === costCenterId);
	}

	const createSection = (): Promise<string | undefined> => {
		return (type === "jobs" ?
				apiInstance.projectsApi.postNewJobSection(projectId, sectionName) :
				apiInstance.projectsApi.postNewQuoteSection(projectId, sectionName)
		)
			.then(
				(response) => {
					if (response.errors.length > 0) {
						response.errors.forEach(error => {
							NotificationService.errorNotification(
								t("common.error"),
								error.message
							)
						})
						return undefined;
					}
					else {
						return response.id
					}
				})
			.catch(
				(error) => {
					LoggerService.logError(error)
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.details.createNewDrawingModal.errorDesc.section_creation_error")
					)
					return undefined;
				}
			)
	}

	const validateIfDrawingNameTaken = () => {
		const selectedCostCenter = findSelectedCostCenter()
		const existingDrawingNames = selectedCostCenter
			? selectedCostCenter.drawings
				.filter(drawing => drawing.drawingType === drawingType)
				.map(drawing => drawing.name)
			: []
		if (existingDrawingNames.includes(name)) {
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.createNewDrawingModal.errorDesc.name_exists")
			);
			return true;
		}
		return false;
	}

	const onSave = (open: boolean = false) => async () => {
		setIsLoading(true)
		let createdCostCenterId;
		let createdSectionId;
		if (saveDisabled || validateIfDrawingNameTaken()) {
			setIsLoading(false)
			return;
		}
		if (isSectionFieldInCreateMode) {
			createdSectionId = await createSection();
		}
		if (isCostCenterFieldInCreateMode && (createdSectionId || sectionId)) {
			createdCostCenterId =
				await createCostCenter(type === "jobs", projectId, createdSectionId ?? sectionId!, costCenterName,
					companyCostCenterId!);
		}
		setIsLoading(false)
		if ((isSectionFieldInCreateMode || createCostCenterFieldMode === CreateDrawingModalFieldMode.CREATE_NEW) &&
			(!(createdSectionId || sectionId) || !createdCostCenterId)) {
			NotificationService.errorNotification(
				t("common.error"),
				t(drawingType === DrawingType.TAKE_OFF ?
					`projects.details.createNewDrawingModal.errorDesc.take_off` :
					`projects.details.createNewDrawingModal.errorDesc.as_built`)
			);
			return;
		}
		const properSectionId = createdSectionId ?? sectionId!
		const properCostCenterId = createdCostCenterId ?? costCenterId!

		if (planPage) {
			createDrawing(
				drawingType, projectId, properSectionId, properCostCenterId, name,
				open ? openDrawingDetails(true, properSectionId, properCostCenterId) : onCreateDrawingsSuccess
			).then()
		}
		else {
			createBlankDrawing(
				drawingType, projectId, properSectionId, properCostCenterId, name,
				open ? openDrawingDetails(true, properSectionId, properCostCenterId) : onCreateDrawingsSuccess
			).then()
		}
	};

	const openDrawingDetails = (showSuccess: boolean,
		givenSectionId: string,
		givenCostCenterId: string) => (drawingDetails: DrawingDetails) => {
		if (showSuccess)
			onCreateDrawingsSuccess();
		if (drawingDetails) {
			navigateTo(pages.projectDrawingPage(
				type, projectId, givenSectionId, givenCostCenterId, drawingDetails.id, INITIAL_VERSION_ID
			))
		}
	};

	const toggleCreateCostCenter = () => {
		setCostCenterName("")
		if (isSectionFieldInCreateMode) {
			setCreateSectionFieldMode(CreateDrawingModalFieldMode.EXISTING)
		}
		setCreateCostCenterFieldMode(
			isCostCenterFieldInCreateMode ?
				CreateDrawingModalFieldMode.EXISTING :
				CreateDrawingModalFieldMode.CREATE_NEW)
	}

	const toggleCreateSection = () => {
		setCostCenterName("")
		setSectionName("")
		setCreateSectionFieldMode(isSectionFieldInCreateMode ?
			CreateDrawingModalFieldMode.EXISTING :
			CreateDrawingModalFieldMode.CREATE_NEW)
		if (isSectionFieldInExistingMode) {
			setCreateCostCenterFieldMode(CreateDrawingModalFieldMode.CREATE_NEW)
		}
		else {
			setCreateCostCenterFieldMode(CreateDrawingModalFieldMode.EXISTING)

		}
	}

	const onCostCenterNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setCostCenterName(evt.target.value)
	}

	const handleCompanyCostCenterChange = (companyCostCenterId: number) => {
		setCompanyCostCenterId(companyCostCenterId);
	};

	const handleCostCenterChange = (costCenterId: string) => {
		setCostCenterId(costCenterId)
	}

	const onSectionNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setSectionName(evt.target.value)
	}

	const fieldLabelTranslations = {
		[DrawingType.TAKE_OFF]: t(`projects.details.createNewDrawingModal.nameLabel.take_off`),
		[DrawingType.AS_BUILT]: t(`projects.details.createNewDrawingModal.nameLabel.as_built`),
		[DrawingType.ASSET_PLAN]: t(`projects.details.createNewDrawingModal.nameLabel.asset_plan`)
	}
	return (
		<EditValueModal isOpen={true}
						onClose={handleClose}
						onSave={onSave()}
						onSaveAndOpen={onSave(true)}
						title={t(drawingType === DrawingType.TAKE_OFF ?
							`projects.details.createNewDrawingModal.title.take_off` :
							`projects.details.createNewDrawingModal.title.as_built`)}
						disableSave={saveDisabled}
						isLoading={saveStatus === Status.LOADING || isLoading}>
			<div className="create-drawing-modal">
				<div className="create-drawing-modal_field">
					<div className="create-drawing-modal_field_label">
						{fieldLabelTranslations[drawingType]}
					</div>
					<DrawingNameInput value={name} onChange={setName}/>
				</div>
				<>
					{!isServiceProject && <CreateSectionInputField
						isSectionFieldInCreateMode={isSectionFieldInCreateMode}
						sectionName={sectionName}
						onSectionNameChange={onSectionNameChange}
						handleSectionChange={handleSectionChange}
						sections={sectionsToSelect}
						sectionId={sectionId}
						toggleCreateSection={toggleCreateSection}/>}
					<CreateCostCenterInputField
						isCostCenterFieldInCreateMode={isCostCenterFieldInCreateMode}
						isSectionFieldInCreateMode={isSectionFieldInCreateMode}
						onCostCenterNameChange={onCostCenterNameChange}
						handleCompanyCostCenterChange={handleCompanyCostCenterChange}
						toggleCreateCostCenter={toggleCreateCostCenter}
						companyCostCenterId={companyCostCenterId}
						costCenterName={costCenterName}
						costCentersToSelect={costCentersToSelect}
						handleCostCenterChange={handleCostCenterChange}
						costCenterId={costCenterId}
					/>
				</>
			</div>
		</EditValueModal>
	);
};

export default CreateDrawingModal;