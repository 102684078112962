import React from "react";
import {selectEditorLockState} from "../config/configSlice";
import {useDispatch, useSelector} from "react-redux";
import SaveDrawing from "./SaveDrawing";
import "./editorHeader.scss";
import {EditorHeaderFinishShapePanel} from "./EditorHeaderFinishShapePanel";
import EditorHeaderUndoPanel from "./EditorHeaderUndoPanel";
import {EditorHeaderExportPanel} from "./EditorHeaderExportPanel";
import IconWithTooltip from "../../../../../../components/ui/IconWithTooltip";
import {useTranslation} from "react-i18next";
import {useZoomActions} from "../view/useZoomActions";
import {EditorHeaderVisibilityDropdown} from "./EditorHeaderVisibilityDropdown";
import {ButtonsGroup, ButtonsGroupDivider, ButtonsGroupSpacer} from "../../../../../../components/layout";
import {ZoomControls} from "../../../../../base-konva/features/view";
import {rotateClockwise, rotateCounterClockwise} from "../../../../../base-konva/utils";
import {selectZoomState, viewActions} from "../view/viewSlice";
import IconRotateCw from "../../icons/unselectableEditorHeaderIcons/IconRotateCw";
import IconRotateCcw from "../../icons/unselectableEditorHeaderIcons/IconRotateCcw";

const CreateAssetsEditorHeader = () => {
	const zoomState = useSelector(selectZoomState);
	const editorLocked = useSelector(selectEditorLockState);
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const {
		minZoom, maxZoom,
		handleZoomOutByPercent,
		handleZoomInByPercent,
		handleZoomReset,
	} = useZoomActions();

	function handleZoomOut() {
		handleZoomOutByPercent(10)
	}

	function handleZoomIn() {
		handleZoomInByPercent(10)
	}

	function handleStageRotateCw() {
		dispatch(viewActions.setZoomState({
			...zoomState,
			rotation: rotateClockwise(zoomState.rotation)
		}))
	}

	function handleStageRotateCcw() {
		dispatch(viewActions.setZoomState({
			...zoomState,
			rotation: rotateCounterClockwise(zoomState.rotation)
		}))
	}

	return (
		<>
			<div className={"editor-header"}>
				<div className={"editor-header_main"}>
					<ButtonsGroup>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.rotateLeft")}
							icon={<IconRotateCcw onClick={handleStageRotateCcw}/>}
						/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.rotateRight")}
							icon={<IconRotateCw onClick={handleStageRotateCw}/>}
						/>
					</ButtonsGroup>
					<ButtonsGroupDivider/>
					<ButtonsGroup>
						<ZoomControls
							currentZoom={Math.round(zoomState.scale * 100)}
							minZoom={minZoom}
							maxZoom={maxZoom}
							handleZoomOut={handleZoomOut}
							handleZoomReset={handleZoomReset}
							handleZoomIn={handleZoomIn}
						/>
					</ButtonsGroup>
					<ButtonsGroupDivider/>
					<ButtonsGroup>
						<EditorHeaderUndoPanel disabled={editorLocked}/>
					</ButtonsGroup>
					<ButtonsGroup>
						<EditorHeaderVisibilityDropdown/>
					</ButtonsGroup>
					<ButtonsGroupSpacer/>
					<ButtonsGroup customClass="save-finish-buttons">
						<EditorHeaderFinishShapePanel/>
						<SaveDrawing/>
						<EditorHeaderExportPanel/>
					</ButtonsGroup>
				</div>
			</div>
		</>
	);
};

export default CreateAssetsEditorHeader
