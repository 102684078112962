import React from "react";
import {CountMeasurementItem, RemoteSymbol} from "../models/editor";
import {useDispatch} from "react-redux";
import "./groupItemPresenter.scss";
import {useHighlightItem, useHighlightSetter} from "../hooks/highlight";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import IconVisibleButton from "./IconVisibleButton";
import {DeleteChildItemActionPayload, PenLine, SetItemVisibilityActionPayload} from "../../../../base-konva/types";
import IndividualAssetAssignment from "./IndividualAssetAssignment";
import {SymbolType} from "../icons/symbol_pack/default";

interface CountGroupItemProps {
    parentId: string,
    item: CountMeasurementItem | PenLine,
    onDelete: ActionCreatorWithPayload<DeleteChildItemActionPayload, string>,
    setItemVisibility: ActionCreatorWithPayload<SetItemVisibilityActionPayload, string>,
    disabled?: boolean,
    assetTypeId?: number
    isAssetPlan: boolean,
    symbol?: SymbolType
    color?: string
    remoteSymbol?: RemoteSymbol
}

const GroupItemPresenter: React.FC<CountGroupItemProps> = ({
                                                               parentId,
                                                               item,
                                                               onDelete,
                                                               setItemVisibility,
                                                               disabled,
                                                               assetTypeId,
                                                               isAssetPlan,
                                                               symbol,
                                                               color,
                                                               remoteSymbol
                                                           }) => {

    const dispatch = useDispatch();
    const highlightActions = useHighlightSetter(item.id);
    const {isItemHover} = useHighlightItem(item.id);

	const toggleVisibility = () => {
		dispatch(setItemVisibility({id: item.id, parentId, visible: !item.visible}))
	}

	function handleUnassignAsset() {
		// TODO
	}

	const handleDelete = () => {
		if (!isAssetPlan) {
			!disabled && dispatch(onDelete({parentId, id: item.id}));
		} else {
			handleUnassignAsset()
		}
	}

	const cls = [
		"group-item-presenter",
		isItemHover && "is-hover",
	].filter(Boolean).join(" ");

	const actionsButtonCls = [
		"group-item-presenter_actions_button",
		disabled && "disabled"
	].filter(Boolean).join(" ");

    return (
        <div className={cls}
             onMouseEnter={highlightActions.setHighlight}
             onMouseLeave={highlightActions.clearHighlight}>
            <div className={"group-item-presenter_label"}>
                {item.name}
            </div>

            <div className={"group-item-presenter_actions"}>
                {!isAssetPlan ? <IconVisibleButton onClick={toggleVisibility} visible={item.visible}/> :
                    <IndividualAssetAssignment
                        id={item.id}
                        materialName={item.name}
                        assetTypeId={assetTypeId}
                        color={color}
                        symbol={symbol}
                        remoteSymbol={remoteSymbol}
                    />
                }
                <div className={actionsButtonCls} onClick={handleDelete}>
                    &times;
                </div>
            </div>
        </div>
    );
};

export default GroupItemPresenter
