import React from "react";
import {Colors} from "../../styles";

type Props = {
    fill?: string
}
const IconMaterial: React.FC<Props> = ({fill = Colors.SIMPROBLUE}) => {
    return (
            <svg className="material-icon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 viewBox="0 0 24 24">
                <title>icon</title>
                <path className="cls-1" fill={fill}
                      d="M12,11.47a.75.75,0,0,1-.38-.1L2.86,6.29a.75.75,0,0,1-.38-.65A.77.77,0,0,1,2.85,5L11.59-.08a.75.75,0,0,1,.75,0L21.14,5a.76.76,0,0,1,.37.65.74.74,0,0,1-.37.65L12.4,11.37A.69.69,0,0,1,12,11.47ZM4.73,5.64,12,9.85l7.25-4.21L12,1.43Z"/>
                <path className="cls-1" fill={fill}
                      d="M13.36,23.91a.79.79,0,0,1-.37-.1.76.76,0,0,1-.38-.66l0-10.1A.76.76,0,0,1,13,12.4l8.74-5.07a.75.75,0,0,1,.75,0,.76.76,0,0,1,.38.66l0,10.1a.76.76,0,0,1-.37.65l-8.74,5.08A.86.86,0,0,1,13.36,23.91Zm.78-10.43,0,8.37,7.23-4.2,0-8.37Z"/>

                <path className="cls-1" fill={fill}
                      d="M10.67,23.91a.86.86,0,0,1-.38-.1L1.5,18.73a.73.73,0,0,1-.37-.65L1.15,8a.76.76,0,0,1,1.13-.65l8.79,5.08a.78.78,0,0,1,.38.65l0,10.11a.74.74,0,0,1-.38.65A.79.79,0,0,1,10.67,23.91Zm-8-6.26,7.29,4.21,0-8.38L2.65,9.27Z"/>
            </svg>
    );
};

export default IconMaterial