import React from "react";
import "../../../components/groupItemPresenter.scss";
import {useTranslation} from "react-i18next";
import {useUserSettingsDataContext} from "../../../../../../../providers/UserSettingsProvider";
import {formatLength} from "../../../../../../../utils/TextUtils";

interface WastageItemProps {
	value: number,
	onDelete: () => void,
	onPropsClick: () => void,
	disabled?: boolean,
	isAssetPlan: boolean
}

const WastageItem: React.FC<WastageItemProps> = ({value, onDelete, onPropsClick, disabled, isAssetPlan}) => {

	const {settings} = useUserSettingsDataContext();
	const {t} = useTranslation();

	const handlePropClick = () => !disabled && onPropsClick();
	const handleDelete = () => !disabled && onDelete();

	const propsCls = [
		"group-item-presenter_props",
		!disabled && "is-clickable",
		disabled && "disabled"
	].filter(Boolean).join(" ");

	const actionsButtonCls = [
		"group-item-presenter_actions_button",
		disabled && "disabled"
	].filter(Boolean).join(" ");

	return (
		<div className={"group-item-presenter"}>
			<div className={"group-item-presenter_label"}>{t("editor.length.wastageItemName")}</div>
			<div className={propsCls} onClick={handlePropClick}>
				{formatLength(value, settings?.measurementSystem)}
			</div>
			<div className={"group-item-presenter_actions"}>
				<div className={actionsButtonCls} onClick={handleDelete}>
					&times;
				</div>
			</div>
		</div>
	);
};

export default WastageItem
