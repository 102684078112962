import {DrawingType, Status} from "../../../models/enums";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {OnSuccessData} from "../../../utils/CallbackUtils";
import DrawingDetails from "../../../models/DrawingDetails";
import {PlanUploadData} from "../features/plan-upload/types";

enum CreateDrawingErrorTypes {
	UNSUPPORTED = "UNSUPPORTED",
	DEFAULT = "DEFAULT"
}

function useCreateDrawings(
	planTemplate: PlanUploadData | undefined,
	pageNumber: number | undefined,
) {
	const planTemplatePage = planTemplate?.pages.find(p => p.pageNumber === pageNumber)
	const [saveStatus, setSaveStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	const translations = {
		"createDrawingsError": {
			[CreateDrawingErrorTypes.DEFAULT]: {
				[DrawingType.TAKE_OFF]: t(`projects.details.createNewDrawingModal.errorDesc.take_off`),
				[DrawingType.AS_BUILT]: t(`projects.details.createNewDrawingModal.errorDesc.as_built`),
				[DrawingType.ASSET_PLAN]: t(`projects.details.createNewDrawingModal.errorDesc.asset_plan`)
			},
			[CreateDrawingErrorTypes.UNSUPPORTED]: {
				[DrawingType.AS_BUILT]: t(`projects.details.createNewDrawingModal.unsupportedFormatErrorDesc.as_built`),
				[DrawingType.TAKE_OFF]: t(`projects.details.createNewDrawingModal.unsupportedFormatErrorDesc.take_off`),
				[DrawingType.ASSET_PLAN]: t(`projects.details.createNewDrawingModal.unsupportedFormatErrorDesc.asset_plan`)
			}
		},
		"createDrawingsSuccess": {
			[DrawingType.TAKE_OFF]: t(`projects.details.createNewDrawingModal.success.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.createNewDrawingModal.success.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.createNewDrawingModal.success.asset_plan`)
		},
		"createBlankDrawingError": {
			[DrawingType.TAKE_OFF]: t(`projects.details.createNewDrawingModal.errorDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.createNewDrawingModal.errorDesc.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.createNewDrawingModal.errorDesc.asset_plan`)
		},
		"createBlankDrawingSuccess": {
			[DrawingType.TAKE_OFF]: t(`projects.details.createNewDrawingModal.success.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.createNewDrawingModal.success.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.createNewDrawingModal.success.asset_plan`)
		}
	}

	async function createDrawing(
		drawingType: DrawingType,
		projectId: string,
		sectionId: string | undefined,
		costCenterId: string | undefined,
		name: string,
		onSuccess: OnSuccessData<DrawingDetails>
	): Promise<void> {
		if (planTemplatePage && drawingType && planTemplate) {
			setSaveStatus(Status.LOADING);
			let drawingDetails = await apiInstance.projectsApi.postNewDrawing(
				projectId, sectionId!, costCenterId!, planTemplate.id, name, drawingType,
				planTemplatePage.pageNumber
			).then((drawingDetails) => {
				return drawingDetails;
			}).catch(err => {
				setSaveStatus(Status.ERROR);
				LoggerService.logError(err);
				let errorKey: CreateDrawingErrorTypes;
				if (err?.response?.data?.exception?.includes("UnsupportedPageFileExtensionException")) {
					errorKey = CreateDrawingErrorTypes.UNSUPPORTED
				}
				else {
					errorKey = CreateDrawingErrorTypes.DEFAULT
				}
				NotificationService.errorNotification(t("common.error"),
					translations["createDrawingsError"][errorKey][drawingType]);
				throw err;
			});
			onSuccess(drawingDetails!);
			setSaveStatus(Status.SUCCESS);
			NotificationService.successNotification(t("common.success"),
				translations["createDrawingsSuccess"][drawingType]);
		}
	}

	function createBlankDrawing(
		drawingType: DrawingType,
		projectId: string,
		sectionId: string | undefined,
		costCenterId: string | undefined,
		name: string,
		onSuccess: OnSuccessData<DrawingDetails>
	): Promise<void> {
		setSaveStatus(Status.LOADING);
		return apiInstance.projectsApi.postNewBlankDrawing(
			projectId, sectionId!, costCenterId!, name, drawingType
		).then((drawingDetails) => {
			onSuccess(drawingDetails);
			setSaveStatus(Status.SUCCESS);
			NotificationService.successNotification(t("common.success"),
				translations["createBlankDrawingSuccess"][drawingType]);
		}).catch(err => {
			setSaveStatus(Status.ERROR);
			NotificationService.errorNotification(t("common.error"),
				translations["createBlankDrawingError"][drawingType]);
			LoggerService.logError(err);
		});
	}

	return {saveStatus, createDrawing, createBlankDrawing};
}

export {useCreateDrawings}
