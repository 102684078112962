import {EditorTool, MaterialType} from "../../../../../../models/enums";
import {countActions} from "../../../../../count/countSlice";
import {lengthActions} from "../../../../../length/lengthSlice";
import {areaActions} from "../../../../../area/areaSlice";
import {configActions} from "../../../../../config/configSlice";
import {useDispatch} from "react-redux";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {MaterialMeasurementCreateActionPayload} from "../../../../../../models/editor";
import {useOneOffData} from "../../../hooks/useOneOffData";

export function useAddItemsOneOffData() {
	const dispatch = useDispatch();
	const {state, nameInput, stylesContainer} = useOneOffData()

	const noVolumeHeight = state.type === EditorTool.VOLUME && (state.height === undefined || state.height === 0)

	const contentFooter = {
		canSubmit: !!nameInput.name && !noVolumeHeight,
		submit: () => {
			if (contentFooter.canSubmit) {
				let measurementMaterialAddAction: ActionCreatorWithPayload<MaterialMeasurementCreateActionPayload, string>

				switch (state.type) {
					case EditorTool.COUNT:
						measurementMaterialAddAction = countActions.addCount
						break;
					case EditorTool.LENGTH:
						measurementMaterialAddAction = lengthActions.addLength
						break;
					default:
						measurementMaterialAddAction = areaActions.addArea
						break;
				}

				dispatch(measurementMaterialAddAction({
					material: {name: nameInput.name!, type: MaterialType.ONE_OFF, isAsset: false, assetTypeId: undefined},
					style: state.measurementStyle,
					height: state.height,
					defaultDrop: state.defaultDrop
				}))
				dispatch(configActions.switchEditorTool({editorTool: state.type}))
			}
		}
	}

	function restoreInitialState() {
		nameInput.setName(undefined)
	}

	return {
		nameInput,
		stylesContainer,
		contentFooter,
		restoreInitialState,
	}
}
