import {DrawingType, Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import NotificationService from "../../../services/NotificationService";
import LoggerService from "../../../services/LoggerService";
import {useTranslation} from "react-i18next";
import {OnSuccess} from "../../../utils/CallbackUtils";

interface UseDrawingLockUnlockHook {
	saveStatus: Status,
	lockDrawing: () => void,
	unlockDrawing: (onSuccess?: OnSuccess) => void
}

function useDrawingLockUnlock(drawingId: string, drawingType: DrawingType): UseDrawingLockUnlockHook {

	const {t} = useTranslation();

	const [saveStatus, setSaveStatus] = useState(Status.IDLE);

	const translations = {
		"lockErrorDesc": {
			[DrawingType.TAKE_OFF]: t(`projects.plan.lockErrorDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.plan.lockErrorDesc.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.plan.lockErrorDesc.asset_plan`)
		},
		"unlockErrorDesc": {
			[DrawingType.TAKE_OFF]: t(`projects.plan.unlockErrorDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.plan.unlockErrorDesc.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.plan.unlockErrorDesc.asset_plan`)
		}
	}

	function lockDrawing() {
		setSaveStatus(Status.LOADING);
		apiInstance.drawingsApi.lockDrawing(drawingId)
			.then(() => setSaveStatus(Status.SUCCESS))
			.catch(err => {
				setSaveStatus(Status.ERROR);
				NotificationService.errorNotification(t("common.error"),
					translations["lockErrorDesc"][drawingType]);
				LoggerService.logError(err);
			});
	}

	function unlockDrawing(onSuccess?: OnSuccess) {
		setSaveStatus(Status.LOADING);
		apiInstance.drawingsApi.unlockDrawing(drawingId)
			.then(() => {
				setSaveStatus(Status.SUCCESS);
				onSuccess && onSuccess();
			})
			.catch(err => {
				setSaveStatus(Status.ERROR);
				NotificationService.errorNotification(t("common.error"),
					translations["unlockErrorDesc"][drawingType]);
				LoggerService.logError(err);
			});
	}

	return {saveStatus, lockDrawing, unlockDrawing};
}

export default useDrawingLockUnlock;
