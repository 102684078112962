import {SerializedMeasurementApiDto} from "./restModels";
import {MaterialType, MeasurementType} from "../features/project-drawings/features/editor/models/enums";
import LoggerService from "../services/LoggerService";

export default class SerializedMeasurement {
	constructor(public readonly id: string,
		public readonly editorId: string,
		public readonly materialId: number | undefined,
		public readonly materialType: MaterialType,
		public readonly materialName: string,
		public readonly materialPartNumber: string | undefined,
		public readonly measurementValue: number,
		public readonly laborTime: number | undefined,
		public readonly fitTimeId: number | undefined,
		public readonly measurementType: MeasurementType,
		public readonly deduction: number | undefined,
		public readonly isAsset: boolean | undefined,
		public readonly assetTypeId: number | undefined,
	) {}

	public static fromJson(json: SerializedMeasurementApiDto): SerializedMeasurement {
		const materialType = MaterialType[json.materialType];
		if (materialType === undefined) {
			const error = new Error(`Unknown Material type: ${json.materialType}`);
			LoggerService.logError(error);
			throw error;
		}
		return new SerializedMeasurement(json.id, json.editorId, json.materialId, materialType, json.materialName,
			json.materialPartNumber, json.measurementValue, json.laborTime, json.fitTimeId, json.measurementType,
			json.deduction, json.isAsset, json.assetTypeId);
	}
}
