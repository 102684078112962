import {DrawingType, Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import NotificationService from "../../../services/NotificationService";
import LoggerService from "../../../services/LoggerService";
import {useTranslation} from "react-i18next";
import {OnSuccess} from "../../../utils/CallbackUtils";
import {LockError} from "../../project-drawings/features/editor/models/enums";

interface UseRenameDrawingHook {
	saveStatus: Status,
	renameDrawing: (newName: string) => void,
}

function useRenameDrawing(drawingId: string, drawingType: DrawingType, onSuccess: OnSuccess): UseRenameDrawingHook {

	const {t} = useTranslation();

	const [saveStatus, setSaveStatus] = useState(Status.IDLE);

	const translations = {
		"renameDrawingSuccess": {
			[DrawingType.TAKE_OFF]: t(`projects.details.renameDrawingModal.renameSuccessDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.renameDrawingModal.renameSuccessDesc.as_built`),
			[DrawingType.ASSET_PLAN]: t(`projects.details.renameDrawingModal.renameSuccessDesc.asset_plan`)
		},
		"renameDrawingError": {
			[LockError.DEFAULT]: {
				[DrawingType.TAKE_OFF]: t(`projects.details.renameDrawingModal.renameErrorDesc.take_off`),
				[DrawingType.AS_BUILT]: t(`projects.details.renameDrawingModal.renameErrorDesc.as_built`),
				[DrawingType.ASSET_PLAN]: t(`projects.details.renameDrawingModal.renameErrorDesc.asset_plan`)
			},
			[LockError.LOCK]: {
				[DrawingType.TAKE_OFF]: t(`projects.details.renameDrawingModal.renameErrorDescLock.take_off`),
				[DrawingType.AS_BUILT]: t(`projects.details.renameDrawingModal.renameErrorDescLock.as_built`),
				[DrawingType.ASSET_PLAN]: t(`projects.details.renameDrawingModal.renameErrorDescLock.asset_plan`)
			}
		}
	}

	function renameDrawing(newName: string) {
		setSaveStatus(Status.LOADING);
		apiInstance.drawingsApi.renameDrawing(drawingId, newName)
			.then(() => {
				setSaveStatus(Status.SUCCESS);
				NotificationService.successNotification(t("common.success"),
					translations["renameDrawingSuccess"][drawingType]);
				onSuccess();
			})
			.catch(err => {
				setSaveStatus(Status.ERROR);
				let errorKey: LockError;
				if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
					errorKey = LockError.LOCK
				}
				else {
					errorKey = LockError.DEFAULT
				}
				NotificationService.errorNotification(t("common.error"),
					translations["renameDrawingError"][errorKey][drawingType]);
				LoggerService.logError(err);
			})
	}

	return {saveStatus, renameDrawing}
}

export default useRenameDrawing;
